import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from '../core/services/user.service';
import { BuddyData } from 'src/app/models';
import { DefaultBuddyData } from 'src/app/constants';

/** This service manages the data that drives the look and accessories of the [Buddy avatar]{@link BuddySVGComponent}. */
@Injectable({
  providedIn: 'root'
})
export class BuddyService {

  /** Required to be true in order to update {@link BuddyData} in the backend. */
  private loggedIn: boolean;
  /** Default value is {@link DefaultBuddyData} */
  private data: BuddyData = { ...DefaultBuddyData };
  /** Stores prevoius data value in order to revert on update error */
  private prevData: BuddyData;
  /**
   * Initial value is {@link DefaultBuddyData}.
   * Subscribed to by {@link BuddySVGComponent}.
   * Subscribed to and set by {@link EditBuddyComponent} and {@link BuddyRegisterComponent}.
   */
  data$: BehaviorSubject<BuddyData> = new BehaviorSubject<BuddyData>(this.data);
  /** Pushes out error to subscribers when Buddy update fails */
  updateError$: Subject<Error> = new Subject<Error>();
  /** Pushes out notification to subscribers when Buddy update succeeds */
  updateSuccess$: Subject<null> = new Subject<null>();
  /** [BuddyResolver]{@link BuddyResolver} distributes the Buddy's name to various components so they can load the proper VO audio. */
  name$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * The constructor subscribes to the UserService's [loggedIn$]{@link UserService#loggedIn$} BehaviorSubject
   * and triggers the methods [loadData]{@link BuddyService#loadData}(if true) and [resetData]{@link BuddyService#resetData}(if false) accordingly.
   */
  constructor(private userService: UserService) {
    this.userService.loggedIn$.subscribe(b => {
      this.loggedIn = b;
      if (b) this.loadData();
      else this.resetData();
    });
  }

  /** Loads BuddyData from the backend via [UserService.getBuddy()]{@link UserService#getBuddy} and starts the Subscription to [data$]{@link BuddyService#data$}. */
  private loadData() {
    this.userService.getBuddy().subscribe(dta => this.onDataLoaded(dta));
    this.data$.subscribe(dta => {
      this.updateData(dta);
    });
  }

  /**
   * Callback in [data$]{@link BuddyService#data$} Subscription.
   * This updates [data]{@link BuddyService#data}, sets [name$]{@link BuddyService#name$}, and updates the backend via [UserService.updateBuddy()]{@link UserService#updateBuddy}.
   * When the backend call is successfull, {@link BuddyService#updateSuccess$} pushes nofitication, and if the call fails, {@link BuddyService#updateErrror$} pushes error and data is reverted to previous state.
   * Does not get called when data$ is set in the [UserService.getBuddy()]{@link UserService#getBuddy} callback, [onDataLoaded]{@link BuddyService#onDataLoaded}.
   */
  private updateData(dta: BuddyData) {
    if (JSON.stringify(dta) !== JSON.stringify(this.data)) {
      this.prevData = JSON.parse(JSON.stringify(this.data));
      this.data = JSON.parse(JSON.stringify(dta));
      this.name$.next(this.data.name);
      if (this.loggedIn) {
        this.userService.updateBuddy(this.data).subscribe(
          () => this.updateSuccess$.next(),
          err => {
            this.updateError$.next(err);
            setTimeout(() => {
              this.data = this.prevData;
              this.data$.next(JSON.parse(JSON.stringify(this.data)));
              this.name$.next(this.data.name);
            }, 2500);
          }
        );
      }
    }
  }

  /** Callback in the [UserService.getBuddy()]{@link UserService#getBuddy} Subscription. Sets [data$]{@link BuddyService#data$} and [name$]{@link BuddyService#name}. */
  private onDataLoaded(dta: BuddyData) {
    this.data = dta;
    this.data$.next(JSON.parse(JSON.stringify(this.data)));
    this.name$.next(this.data.name);
  }

  /** Resets data and data$ to {@link DefaultBuddyData} and name$ to null on logout. */
  private resetData() {
    this.data = { ...DefaultBuddyData };
    this.data$.next(this.data);
    this.name$.next(null);
  }
}
