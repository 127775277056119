import { Component, OnChanges, Input, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { slideUpAnimation } from 'src/app/animations/slide-up.animation';
import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

@Component({
  selector: 'ppt-circle-menu',
  templateUrl: './circle-menu.component.html',
  styleUrls: ['./circle-menu.component.scss'],
  animations: [slideUpAnimation]
})
export class CircleMenuComponent implements OnChanges, AfterViewInit {

  @ViewChild('svg', {static: true}) svgRef: ElementRef; // #svg-menu-wrap
  @ViewChild('btns', {static: true}) btnsRef: ElementRef;

  @Input() mode: string; // login || menu
  @Input() show: boolean;
  @Input() svgID: string;

  menuState = 'show'; // show || hide

  private startPaths: NodeList;
  private endPaths: NodeList;
  private width100 = 280;

  @HostListener('window:resize', ['$event'])
  onResize() {
    /* svgRef is div thats wraps the svg. Firefox cannot get clientWidth from svg element */
    let scl = this.svgRef.nativeElement.clientWidth / this.width100;
    gsap.set(this.btnsRef.nativeElement, { scale: scl });
    scl -= 0.33;
    scl = scl <= 0.55 ? 55 : ( scl >= 1.5 ? 150 : Math.round(scl * 100) );
    document.documentElement.style.fontSize = scl + '%';
  }

  constructor() {
    gsap.registerPlugin(MorphSVGPlugin);
    MorphSVGPlugin.defaultType = 'rotational';
  }

  ngOnChanges(e) {
    //
    if (e.show && e.show.currentValue !== undefined) {
      this.menuState = this.show ? 'show' : 'hide';
    }
    //
    if (e.svgID && e.svgID.currentValue) {
      window.dispatchEvent(new Event('resize'));
      this.changeLogo(this.svgID);
    }
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  private changeLogo(id: string) {
    /* console.log('CircleMenuComponent.changeLogo()', id); */
    this.startPaths = document.querySelectorAll('#svg-logo path');
    this.endPaths = document.querySelectorAll(`#svg-${id} path`);
    if (this.endPaths.length > 0) {
      this.startPaths.forEach((path, index) => {
        gsap.to(path, {
          morphSVG: this.endPaths[index],
          duration: 1,
          ease: 'power2.inOut',
          // tslint:disable-next-line: deprecation
          onCompleteParams: [(this.endPaths[index] as SVGPathElement).className.baseVal],
          // tslint:disable-next-line:object-literal-shorthand
          onComplete: function(c) {
            this.targets()[0].className.baseVal = c;
          }
        });
      });
    }
  }

}
