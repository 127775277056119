/// <reference types="@types/soundjs" />
import { Injectable } from '@angular/core';
import 'latest-createjs/lib/soundjs/soundjs';
import { Subject } from 'rxjs';


/**
 * AudioFlyService loads and plays audio on the fly.
 *
 * This service is mostly used for playing Buddy VO/feedback that is dependant on which buddy and/or the user's choices during activities (ie: audio that is not preloaded).
 */
@Injectable({
  providedIn: 'root'
})
export class AudioFlyService {

  /** Components or other services subscribe to for reference to sound instance ({@link https://www.createjs.com/docs/soundjs/classes/AbstractSoundInstance.html createjs.AbstractSoundInstance}). */
  audio$ = new Subject<createjs.AbstractSoundInstance>();
  // tslint:disable-next-line: ban-types
  private listener: Function;
  /** Used to compare e.src in onAudioLoaded callback method. If they don't match, the sound is not pushed out by audio$.next. */
  private curSource: string;

  constructor() {}

  /**
   * Method to register, load, and play audio file on the fly. Props is passed in a data object along with a startTime property
   * used to possibly redefine delay property in props object by compensating for the time it took to load the audio file.
   * @param src Source of audio to be loaded. Either a string defining the path or an object that gives path and possible id {id: string, src: string}.
   * @param props Optional props object that is passed as property of a data object into the {@link https://www.createjs.com/docs/soundjs/classes/Sound.html#method_play createjs.Sound.play} method in the callback onAudioLoaded.
   */
  registerSound(src: string | { [key: string]: string | number }, props?: any) {
    if (this.listener) createjs.Sound.off('fileload', this.listener);
    const source = typeof src === 'string' ? src as string : (src as { [key: string]: string | number }).src as string;
    this.curSource = source;
    const data = props ? { props, startTime: props.delay ? Date.now() : 0 } : {};
    this.listener = createjs.Sound.on('fileload', this.onAudioLoaded, this, false, data );
    if (typeof createjs.Sound.registerSound(src) !== 'object') {
      this.onAudioLoaded({ src: source }, data);
    }
  }

  /**
   * File load callback. The audio$ Observable pushes out the next audio instance.
   * @param e Event for newly loaded audio or a src object {src: string} for audio already loaded.
   * @param data Optional props object passed in registerSound and passed to createjs.Sound.on method as property of data object.
   */
  private onAudioLoaded(e: createjs.Event | { src: string }, data?: any) {
    if (this.curSource !== e.src) return;
    createjs.Sound.off('fileload', this.listener);
    if (data && data.props && data.props.delay) {
      data.props.delay -= Date.now() - data.startTime;
      if (data.props.delay < 0) data.props.delay = 0;
    }
    this.listener = undefined;
    const audio = createjs.Sound.play(e.src, data.props ? data.props : null) as createjs.WebAudioSoundInstance;
    /* timeout to let audio.sourceNode be defined  */
    setTimeout(() => {
      if (audio.sourceNode && audio.sourceNode.context.state === 'suspended' && (audio.sourceNode.context as AudioContext).resume) {
        /* resume context: should help w no audio after device sleep  */
        (audio.sourceNode.context as AudioContext).resume();
      }
    }, 2000);
    this.audio$.next(audio);
  }
}
