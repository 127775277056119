import { Injectable } from '@angular/core';
import { AssetLoadService } from './asset-load.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuData } from '../../models';
import { Location } from '@angular/common';
import { JsonPaths, RegistrationMenuData, UserMenuData, AdminMenuData, ParentMenuData, ParentUserMenuData, ProviderMenuData, ProviderUserMenuData, ProjectInfoMenuData, TechSuppotMenuData, EulaInfoMenuData } from 'src/app/constants';

/**
 * Service that manages data that dives circle and top menus, plus toggling visibilty of the circle, top, and user menus.
 *
 * See {@link AppComponent}, {@link CircleMenuBtnsComponent}, {@link MenusShellComponent}, {@link MenuDataResolver},
 * {@link NextLinkResolver},  {@link TopMenuComponent},  {@link TopNavBtnsComponent}, {@link UserMenuComponent}
 */
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  /** Holds MenuData loaded from ./assets/json/menus.json */
  private menuData: MenuData;
  /** Holds MenuData of current menu, either circle or top */
  curMenuData$ = new BehaviorSubject<MenuData>(null);
  /** Boolean that toggles showing top menu vs circle menu */
  showCircleMenu$ = new Subject<boolean>();
  /** Boolean that toggle showing the user menu */
  showUserMenu$ = new Subject<boolean>();

  /** Loads menu.json on initialization, and sets menuData and curMenuData$ on success. */
  constructor(private assetService: AssetLoadService, private loc: Location) {
    this.assetService.loadJSON(JsonPaths.MENU).subscribe(
      dta => this.menuData = this.addBackPaths(dta),
      err => console.log(err),
      () => this.setCurMenuData(this.loc.path())
    );
  }

  /**
   * Sets curMenuData$ based on path.
   * See [MenusShellComponent.onNavEnd()]{@link MenusShellComponent#onNavEnd}
   * @param path Current path of app
   */
  setCurMenuData(path: string) {
    path = path.split('?')[0];
    const data = this.menuData;
    if (!data) return;
    //
    let curDta: MenuData;
    //
    switch (path) {
      case data.path:
        curDta = data;
        break;

      case '/user':
        curDta = UserMenuData;
        break;

      case '/project-info':
        curDta = ProjectInfoMenuData;
        break;

      case '/eula-info':
        curDta = EulaInfoMenuData;
        break;

      case '/tech-support':
        curDta = TechSuppotMenuData;
        break;

      case '/admin':
        curDta = AdminMenuData;
        break;

      case '/parent-guardian':
        curDta = ParentMenuData;
        break;

      case '/parent-guardian/profile':
        curDta = ParentUserMenuData;
        break;

      case '/provider':
        curDta = ProviderMenuData;
        break;

      case '/provider/profile':
        curDta = ProviderUserMenuData;
        break;

      default:
        curDta = this.findMenuItem(this.getMenuDataMenu(path), path);
        break;
    }
    this.curMenuData$.next(curDta);
  }

  /**
   * Gets MenuData of the parent of menuItem. Used for finding next links and creating top menus.
   *
   * See [NextLinkResolver.getNextLink()]{@link NextLinkResolver#getNextLink}, [TopNavBtnsComponent.create()]{@link TopNavBtnsComponent#create}
   * @param menuItem MenuData of item that is child of desired parent MenuData
   * @param includeSubpages If true method may return subpage menu data
   */
  getParentMenu(menuItem: MenuData, includeSubpages: boolean = true): MenuData[] {
    return this.findParentMenu(this.menuData.menu, menuItem, includeSubpages);
  }

  /**
   * Gets MenuData based on path param.
   * See {@link MenuDataResolver}, {@link NextLinkResolver}
   * @param path Path correlating to desired MenuData
   */
  getMenuItem(path: string): MenuData {
    const menu = this.getMenuDataMenu(path);
    return this.findMenuItem(menu, path);
  }

  /**
   * Retrieves the page title of the current page.
   * See [AppComponent.setTitle()]{@link AppComponent#setTitle}
   */
  getCurrentPageTitle(): string | undefined {
    const data = this.menuData;
    if (!data) return undefined;
    const url = decodeURIComponent(this.loc.path());
    if (url === data.path || url === data.id) {
      return data.title;
    }
    const menuItem = this.findMenuItem(this.getMenuDataMenu(url), url);
    return menuItem ? menuItem.title : undefined;
  }

  /** Method used by the public method, getParentMenu, to retrieve parent MenuData. */
  private findParentMenu(searchMenu: MenuData[], match: MenuData, includeSubpages: boolean = true): MenuData[] {
    let menu: MenuData[];
    if (searchMenu) {
      searchMenu.some(menuData => {
        if (menuData === match) {
          menu = includeSubpages ? searchMenu : this.getMenuItem(menuData.back).menu;
          return true;
        } else if (menuData.menu) {
          menu = this.findParentMenu(menuData.menu, match, includeSubpages);
          return menu ? true : false;
        } else if (menuData.subpages) {
          menu = this.findParentMenu(menuData.subpages, match, includeSubpages);
          return menu ? true : false;
        } else {
          return false;
        }
      });
      return menu;
    }
  }

  /** Method used by the public method, getMenuItem, to retrieve item MenuData. */
  private findMenuItem(searchMenu: MenuData[], path: string): MenuData {
    path = path.split('?')[0];
    let menuItem: MenuData;
    if (searchMenu) {
      searchMenu.some(menuData => {
        if (menuData.path === path) {
          menuItem = menuData;
          return true;
        } else if (menuData.menu) {
          menuItem = this.findMenuItem(menuData.menu, path);
          return menuItem ? true : false;
        } else if (menuData.subpages) {
          menuItem = this.findMenuItem(menuData.subpages, path);
          return menuItem ? true : false;
        } else {
          return false;
        }
      });
    }
    return menuItem;
  }

  /** Recursively adds back paths in form of menu.back or subpages.back properties of MenuData. */
  private addBackPaths(data: MenuData): MenuData {
    const menuType = data.subpages ? 'subpages' : 'menu';
    const url = data.path ? (menuType === 'subpages' ? this.getBackAPath(data.path) : data.path) : undefined;
    data[menuType].forEach( item => {
      if (url) item.back = item.back ? item.back : url;
      if (item.menu || item.subpages) this.addBackPaths(item);
    });
    return data;
  }

  /** Returns one path up or back path of url param. */
  private getBackAPath(url: string): string {
    const split = url.split('/');
    split.pop();
    return split.join('/');
  }

  /** Gets an array of MenuData (root data) of possible MeuData not included in ./assets/json/menus.json based on path param, or returns menuData.menu . */
  private getMenuDataMenu(path: string): MenuData[] {
    if ( path.includes('/user') ) {
      return UserMenuData.subpages;
    } else if ( path.includes('/register') ) {
      return RegistrationMenuData.subpages;
    } else if ( path.includes('/admin') ) {
      return AdminMenuData.subpages;
    } else if (path.includes('/parent-guardian')) {
      return ParentMenuData.menu;
    } else if (path.includes('/provider')) {
      return ProviderMenuData.menu;
    } else {
      return this.menuData.menu;
    }
  }
}
