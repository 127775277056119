	<div class="content-wrap circle-menu-holder" [@slideUpAnimation]="menuState">
    <div class="center-wrap {{ mode }}">
      <div #svg id="svg-menu-wrap">
        <svg id="svg-menu" class="center-inner" xmlns="http://www.w3.org/2000/svg" viewBox="-33 -33 733 733">

          <g id="svg-logo">
            <path class="svg-white-fill" d="M607,505.13a8.83,8.83,0,0,1,2.75-6.45,9.44,9.44,0,0,1,7-2.75,10.14,10.14,0,0,1,7.09,2.62,9,9,0,0,1,0,13.16,10.14,10.14,0,0,1-7.09,2.62,9.43,9.43,0,0,1-7-2.75A8.82,8.82,0,0,1,607,505.13Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M566.43,408.1H526.57V388H627V408.1H588.68V513H566.43Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M415.17,388h29.49l60.57,92.88h.35V388h22.25V513H499.58l-61.8-95.88h-.35V513H415.17Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M382.58,388h22.25V513H382.58Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M330.16,255.57h26l53.5,82H410v-82h19.65V366h-25L350.12,281.3h-.31V366H330.16Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M175.67,255h61.77a123.91,123.91,0,0,1,25.56,2.6,63.1,63.1,0,0,1,21.67,8.69,44.67,44.67,0,0,1,14.92,16.35q5.58,10.26,5.58,25.56,0,17.39-6,28a42.71,42.71,0,0,1-16.09,16.48,67.22,67.22,0,0,1-23.49,7.92,181.31,181.31,0,0,1-27.9,2.08H208.37V513.43h-32.7Zm52.43,79.67q7,0,14.53-.52a44.59,44.59,0,0,0,13.89-3.12,24.63,24.63,0,0,0,10.38-7.79q4-5.19,4-14.53,0-8.56-3.63-13.63a24.12,24.12,0,0,0-9.34-7.79,39.45,39.45,0,0,0-12.72-3.5,122.27,122.27,0,0,0-13.49-.78H208.37v51.65Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M295.3,255.57H315V418.7H295.3Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M219.82,455.45a91.62,91.62,0,0,1,6.26-34.52,77.47,77.47,0,0,1,17.32-26.48,75.94,75.94,0,0,1,26.37-16.87,92.49,92.49,0,0,1,33.63-5.92,94,94,0,0,1,34,5.48,74.28,74.28,0,0,1,26.59,16.65,76.94,76.94,0,0,1,17.43,26.37,90.81,90.81,0,0,1,6.26,34.41,87.86,87.86,0,0,1-6.26,33.74,77.38,77.38,0,0,1-17.43,26.14,79.59,79.59,0,0,1-26.59,17.09,94.11,94.11,0,0,1-34,6.37A92.55,92.55,0,0,1,269.76,532a75.4,75.4,0,0,1-43.69-42.79A87.81,87.81,0,0,1,219.82,455.45Zm29.5-1.79a68,68,0,0,0,3.91,23.46,55,55,0,0,0,11.06,18.55A51.42,51.42,0,0,0,281.38,508a53.58,53.58,0,0,0,22.23,4.47A54.37,54.37,0,0,0,326,508a51.15,51.15,0,0,0,17.21-12.29,55.12,55.12,0,0,0,11.06-18.55,68.05,68.05,0,0,0,3.91-23.46,61.74,61.74,0,0,0-3.91-22.12,54.86,54.86,0,0,0-11.06-18A50.6,50.6,0,0,0,326,401.49a55.63,55.63,0,0,0-22.34-4.36,54.81,54.81,0,0,0-22.23,4.36,50.85,50.85,0,0,0-17.1,12.07,54.76,54.76,0,0,0-11.06,18A61.67,61.67,0,0,0,249.31,453.67Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M261.77,455.12a45.81,45.81,0,0,1,3.13-17.26,38.73,38.73,0,0,1,8.66-13.24,38,38,0,0,1,13.18-8.43,46.24,46.24,0,0,1,16.81-3,47,47,0,0,1,17,2.74,37.14,37.14,0,0,1,13.3,8.32,38.47,38.47,0,0,1,8.71,13.18,45.4,45.4,0,0,1,3.13,17.21,43.93,43.93,0,0,1-3.13,16.87,38.69,38.69,0,0,1-8.71,13.07,39.79,39.79,0,0,1-13.3,8.55,47.05,47.05,0,0,1-17,3.18,46.27,46.27,0,0,1-16.81-3A37.7,37.7,0,0,1,264.9,472,43.9,43.9,0,0,1,261.77,455.12Zm14.75-.89a34,34,0,0,0,2,11.73,27.51,27.51,0,0,0,5.53,9.27,25.71,25.71,0,0,0,8.55,6.14,26.79,26.79,0,0,0,11.12,2.23,27.19,27.19,0,0,0,11.17-2.23,25.58,25.58,0,0,0,8.6-6.14A27.56,27.56,0,0,0,329,466a34,34,0,0,0,2-11.73,30.87,30.87,0,0,0-2-11.06,27.43,27.43,0,0,0-5.53-9,25.3,25.3,0,0,0-8.6-6A27.81,27.81,0,0,0,303.67,426a27.41,27.41,0,0,0-11.12,2.18,25.43,25.43,0,0,0-8.55,6,27.38,27.38,0,0,0-5.53,9A30.84,30.84,0,0,0,276.52,454.22Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M284.85,454.93a20.61,20.61,0,0,1,1.41-7.77,17.43,17.43,0,0,1,3.9-6,17.09,17.09,0,0,1,5.93-3.8,20.81,20.81,0,0,1,7.57-1.33,21.16,21.16,0,0,1,7.64,1.23,16.71,16.71,0,0,1,6,3.75A17.31,17.31,0,0,1,321.2,447a20.43,20.43,0,0,1,1.41,7.74,19.77,19.77,0,0,1-1.41,7.59,17.41,17.41,0,0,1-3.92,5.88,17.91,17.91,0,0,1-6,3.85,21.17,21.17,0,0,1-7.64,1.43,20.82,20.82,0,0,1-7.57-1.33,17,17,0,0,1-9.83-9.63A19.76,19.76,0,0,1,284.85,454.93Zm6.64-.4a15.3,15.3,0,0,0,.88,5.28,12.38,12.38,0,0,0,2.49,4.17,11.57,11.57,0,0,0,3.85,2.77,12.06,12.06,0,0,0,5,1,12.23,12.23,0,0,0,5-1A11.51,11.51,0,0,0,312.6,464a12.4,12.4,0,0,0,2.49-4.17,15.31,15.31,0,0,0,.88-5.28,13.89,13.89,0,0,0-.88-5,12.34,12.34,0,0,0-2.49-4,11.39,11.39,0,0,0-3.87-2.72,12.52,12.52,0,0,0-5-1,12.33,12.33,0,0,0-5,1,11.44,11.44,0,0,0-3.85,2.72,12.32,12.32,0,0,0-2.49,4A13.88,13.88,0,0,0,291.48,454.53Z" transform="translate(-68 -66)"/>
          </g>

          <g id="svg-home">
            <path class="svg-white-fill" d="M607,505.13a8.83,8.83,0,0,1,2.75-6.45,9.44,9.44,0,0,1,7-2.75,10.14,10.14,0,0,1,7.09,2.62,9,9,0,0,1,0,13.16,10.14,10.14,0,0,1-7.09,2.62,9.43,9.43,0,0,1-7-2.75A8.82,8.82,0,0,1,607,505.13Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M566.43,408.1H526.57V388H627V408.1H588.68V513H566.43Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M415.17,388h29.49l60.57,92.88h.35V388h22.25V513H499.58l-61.8-95.88h-.35V513H415.17Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M382.58,388h22.25V513H382.58Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M330.16,255.57h26l53.5,82H410v-82h19.65V366h-25L350.12,281.3h-.31V366H330.16Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M175.67,255h61.77a123.91,123.91,0,0,1,25.56,2.6,63.1,63.1,0,0,1,21.67,8.69,44.67,44.67,0,0,1,14.92,16.35q5.58,10.26,5.58,25.56,0,17.39-6,28a42.71,42.71,0,0,1-16.09,16.48,67.22,67.22,0,0,1-23.49,7.92,181.31,181.31,0,0,1-27.9,2.08H208.37V513.43h-32.7Zm52.43,79.67q7,0,14.53-.52a44.59,44.59,0,0,0,13.89-3.12,24.63,24.63,0,0,0,10.38-7.79q4-5.19,4-14.53,0-8.56-3.63-13.63a24.12,24.12,0,0,0-9.34-7.79,39.45,39.45,0,0,0-12.72-3.5,122.27,122.27,0,0,0-13.49-.78H208.37v51.65Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M295.3,255.57H315V418.7H295.3Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M219.82,455.45a91.62,91.62,0,0,1,6.26-34.52,77.47,77.47,0,0,1,17.32-26.48,75.94,75.94,0,0,1,26.37-16.87,92.49,92.49,0,0,1,33.63-5.92,94,94,0,0,1,34,5.48,74.28,74.28,0,0,1,26.59,16.65,76.94,76.94,0,0,1,17.43,26.37,90.81,90.81,0,0,1,6.26,34.41,87.86,87.86,0,0,1-6.26,33.74,77.38,77.38,0,0,1-17.43,26.14,79.59,79.59,0,0,1-26.59,17.09,94.11,94.11,0,0,1-34,6.37A92.55,92.55,0,0,1,269.76,532a75.4,75.4,0,0,1-43.69-42.79A87.81,87.81,0,0,1,219.82,455.45Zm29.5-1.79a68,68,0,0,0,3.91,23.46,55,55,0,0,0,11.06,18.55A51.42,51.42,0,0,0,281.38,508a53.58,53.58,0,0,0,22.23,4.47A54.37,54.37,0,0,0,326,508a51.15,51.15,0,0,0,17.21-12.29,55.12,55.12,0,0,0,11.06-18.55,68.05,68.05,0,0,0,3.91-23.46,61.74,61.74,0,0,0-3.91-22.12,54.86,54.86,0,0,0-11.06-18A50.6,50.6,0,0,0,326,401.49a55.63,55.63,0,0,0-22.34-4.36,54.81,54.81,0,0,0-22.23,4.36,50.85,50.85,0,0,0-17.1,12.07,54.76,54.76,0,0,0-11.06,18A61.67,61.67,0,0,0,249.31,453.67Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M261.77,455.12a45.81,45.81,0,0,1,3.13-17.26,38.73,38.73,0,0,1,8.66-13.24,38,38,0,0,1,13.18-8.43,46.24,46.24,0,0,1,16.81-3,47,47,0,0,1,17,2.74,37.14,37.14,0,0,1,13.3,8.32,38.47,38.47,0,0,1,8.71,13.18,45.4,45.4,0,0,1,3.13,17.21,43.93,43.93,0,0,1-3.13,16.87,38.69,38.69,0,0,1-8.71,13.07,39.79,39.79,0,0,1-13.3,8.55,47.05,47.05,0,0,1-17,3.18,46.27,46.27,0,0,1-16.81-3A37.7,37.7,0,0,1,264.9,472,43.9,43.9,0,0,1,261.77,455.12Zm14.75-.89a34,34,0,0,0,2,11.73,27.51,27.51,0,0,0,5.53,9.27,25.71,25.71,0,0,0,8.55,6.14,26.79,26.79,0,0,0,11.12,2.23,27.19,27.19,0,0,0,11.17-2.23,25.58,25.58,0,0,0,8.6-6.14A27.56,27.56,0,0,0,329,466a34,34,0,0,0,2-11.73,30.87,30.87,0,0,0-2-11.06,27.43,27.43,0,0,0-5.53-9,25.3,25.3,0,0,0-8.6-6A27.81,27.81,0,0,0,303.67,426a27.41,27.41,0,0,0-11.12,2.18,25.43,25.43,0,0,0-8.55,6,27.38,27.38,0,0,0-5.53,9A30.84,30.84,0,0,0,276.52,454.22Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M284.85,454.93a20.61,20.61,0,0,1,1.41-7.77,17.43,17.43,0,0,1,3.9-6,17.09,17.09,0,0,1,5.93-3.8,20.81,20.81,0,0,1,7.57-1.33,21.16,21.16,0,0,1,7.64,1.23,16.71,16.71,0,0,1,6,3.75A17.31,17.31,0,0,1,321.2,447a20.43,20.43,0,0,1,1.41,7.74,19.77,19.77,0,0,1-1.41,7.59,17.41,17.41,0,0,1-3.92,5.88,17.91,17.91,0,0,1-6,3.85,21.17,21.17,0,0,1-7.64,1.43,20.82,20.82,0,0,1-7.57-1.33,17,17,0,0,1-9.83-9.63A19.76,19.76,0,0,1,284.85,454.93Zm6.64-.4a15.3,15.3,0,0,0,.88,5.28,12.38,12.38,0,0,0,2.49,4.17,11.57,11.57,0,0,0,3.85,2.77,12.06,12.06,0,0,0,5,1,12.23,12.23,0,0,0,5-1A11.51,11.51,0,0,0,312.6,464a12.4,12.4,0,0,0,2.49-4.17,15.31,15.31,0,0,0,.88-5.28,13.89,13.89,0,0,0-.88-5,12.34,12.34,0,0,0-2.49-4,11.39,11.39,0,0,0-3.87-2.72,12.52,12.52,0,0,0-5-1,12.33,12.33,0,0,0-5,1,11.44,11.44,0,0,0-3.85,2.72,12.32,12.32,0,0,0-2.49,4A13.88,13.88,0,0,0,291.48,454.53Z" transform="translate(-68 -66)"/>
          </g>

          <g id="svg-sickle-cell">
            <path class="svg-white-fill" d="M523.62,526.05H478.25V501.92H519.1V487.59H478.25V465.72h43.11V451.39H462.41v74.43a48.57,48.57,0,0,1-13.54,10.52,42.27,42.27,0,0,1-19.34,4.75,55.55,55.55,0,0,1-23-4.63,53.44,53.44,0,0,1-17.72-12.74,57.1,57.1,0,0,1-11.46-19.23,70.27,70.27,0,0,1-4.06-24.31,63.75,63.75,0,0,1,4.06-22.93,56.86,56.86,0,0,1,11.46-18.65,52.81,52.81,0,0,1,17.72-12.5,56.83,56.83,0,0,1,23-4.52,47.31,47.31,0,0,1,17,3.24q8.44,3.26,17.48,13l22.7-16.67q-12.28-14.13-26.52-20a79.89,79.89,0,0,0-30.92-5.91,95.82,95.82,0,0,0-34.85,6.14,78.66,78.66,0,0,0-27.33,17.48,80.21,80.21,0,0,0-17.95,27.45A94.82,94.82,0,0,0,342.68,482a90.88,90.88,0,0,0,6.49,35,78.14,78.14,0,0,0,45.28,44.35,95.82,95.82,0,0,0,34.85,6.14,84.51,84.51,0,0,0,34.05-7,64.94,64.94,0,0,0,25.53-20.16h34.74Z" />
            <path class="svg-white-fill" d="M478.32,271.47a10.13,10.13,0,0,0-1.83-3,9.63,9.63,0,0,0-2.82-2.16,10.62,10.62,0,0,0-3.58-1.07v-.12a11.06,11.06,0,0,0,5.18-3.26,8.48,8.48,0,0,0,2-5.76,9.33,9.33,0,0,0-4-8,11.64,11.64,0,0,0-4.05-1.8,19.22,19.22,0,0,0-4.59-.56H448.56v25.15l-10.83-25.15h-6.35l-17.92,41.2h8.21l3.72-9.43h17.86l3.84,9.43H463.4a28.2,28.2,0,0,0,5.73-.58,15.09,15.09,0,0,0,5-1.95,10.49,10.49,0,0,0,3.52-3.61,10.79,10.79,0,0,0,1.34-5.61A10,10,0,0,0,478.32,271.47Zm-50.43-.26,6.46-16.76,6.34,16.76Zm28-19.2h6.05c2.79,0,4.82.43,6.08,1.31a5,5,0,0,1,.06,7.74,8.86,8.86,0,0,1-5.5,1.42h-6.69Zm14.9,25.8a5.83,5.83,0,0,1-2.22,1.84,9,9,0,0,1-2.93.81,27,27,0,0,1-3,.18h-6.75V268.77h7c3,0,5.19.42,6.6,1.28s2.13,2.36,2.13,4.53A5.36,5.36,0,0,1,470.79,277.81Z" />
            <path class="svg-white-fill" d="M639.99,526.05L602.16,526.05,602.16,451.39,586.32,451.39,586.32,526.55,549.46,525.83,550.92,451.18,535.08,450.87,533.35,539.85,587.04,540.42,587.04,540.38,639.99,540.38,639.99,526.05,639.99,526.05z"/>
            <path class="svg-red-fill" d="M559.8,373.14L559.8,350.68,597.81,350.68,597.81,337.35,559.8,337.35,559.8,317,599.92,317,599.92,303.66,545.07,303.66,545.07,373.14,510.1,373.14,510.1,303.66,495.36,303.66,495.36,386.47,545.07,386.47,545.3,386.47,602.02,386.47,602.02,373.14,559.8,373.14,559.8,373.14z" />
            <path class="svg-red-fill" d="M444.12,341.91L482.83,303.66,462.95,303.66,428.68,338.75,427.98,338.75,427.98,303.66,413.24,303.66,413.24,386.47,427.98,386.47,427.98,345.77,428.8,345.77,464.71,386.47,485.41,386.47,444.12,341.91,444.12,341.91z"/>
            <path class="svg-red-fill" d="M320.89,401A68.82,68.82,0,0,0,299.61,380a113.54,113.54,0,0,0-27.8-12.36q-15.1-4.46-27.81-9.27a84.28,84.28,0,0,1-21.28-11.33q-8.58-6.51-8.58-18.88A28.22,28.22,0,0,1,217.57,314a28.73,28.73,0,0,1,9.1-9.78,39.64,39.64,0,0,1,12.7-5.5A61.54,61.54,0,0,1,254,297a64.16,64.16,0,0,1,24.2,4.63,40,40,0,0,1,18.36,14.59L328.1,282.9a86.36,86.36,0,0,0-32.44-19.23,120.13,120.13,0,0,0-37.24-5.83,123.21,123.21,0,0,0-33,4.46,88.52,88.52,0,0,0-28.66,13.56,70.56,70.56,0,0,0-20.26,22.82q-7.71,13.74-7.72,32.61,0,21.63,8.58,34.5a67.72,67.72,0,0,0,21.28,20.6,116.73,116.73,0,0,0,27.81,12.18q15.1,4.47,27.8,9.44a75.83,75.83,0,0,1,21.28,12.19q8.58,7.2,8.58,20.59a28,28,0,0,1-3.77,14.59,32.25,32.25,0,0,1-10,10.47A46.49,46.49,0,0,1,256.7,472a57.28,57.28,0,0,1-15.1,2.06,58.25,58.25,0,0,1-27.29-6.7,54.56,54.56,0,0,1-20.77-18.7l-32.6,31.58a83.88,83.88,0,0,0,36,25.4,127.58,127.58,0,0,0,43.59,7.55,118.21,118.21,0,0,0,34-4.81A83.66,83.66,0,0,0,302.87,494a69.69,69.69,0,0,0,19.4-24.2q7.2-14.6,7.2-34.16Q329.47,414,320.89,401Z" />
            <path class="svg-red-fill" d="M391.14,365.18a26.53,26.53,0,0,1-8.83,7.66,21.39,21.39,0,0,1-9.77,2.4,28.08,28.08,0,0,1-11.64-2.34,26.84,26.84,0,0,1-8.94-6.43,28.74,28.74,0,0,1-5.79-9.71,35.44,35.44,0,0,1-2.05-12.28,32.2,32.2,0,0,1,2.05-11.58,28.58,28.58,0,0,1,5.79-9.41,26.55,26.55,0,0,1,8.94-6.32,28.71,28.71,0,0,1,11.64-2.28,23.89,23.89,0,0,1,8.6,1.64,24.64,24.64,0,0,1,8.83,6.55l11.46-8.42A36.41,36.41,0,0,0,388,304.54a40.36,40.36,0,0,0-15.62-3,48.32,48.32,0,0,0-17.6,3.1A38.88,38.88,0,0,0,334,323V303.66H319.26v82.81H334V367.35a39.16,39.16,0,0,0,7,9.35,40.12,40.12,0,0,0,13.8,8.78,48.53,48.53,0,0,0,17.6,3.1,42.64,42.64,0,0,0,17.2-3.51,33.17,33.17,0,0,0,13.68-11.23Z" />
            <path class="svg-white-fill" d="M567.73,245.72H560.4v25.84a11.77,11.77,0,0,1-.47,3.17,8.79,8.79,0,0,1-1.54,3,8.17,8.17,0,0,1-2.85,2.3,11.07,11.07,0,0,1-8.79,0,8.17,8.17,0,0,1-2.85-2.3,8.79,8.79,0,0,1-1.54-3,11.77,11.77,0,0,1-.47-3.17V245.72h-7.33v.43a29.72,29.72,0,0,0-16.68-15,38,38,0,0,0-13.7-2.2,37.28,37.28,0,0,0-13.56,2.38A30.84,30.84,0,0,0,480,238.14a31.18,31.18,0,0,0-7,10.68,37,37,0,0,0-2.52,13.92A35.48,35.48,0,0,0,473,276.35a30.37,30.37,0,0,0,17.62,17.26A37.28,37.28,0,0,0,504.18,296a38,38,0,0,0,13.7-2.56,32,32,0,0,0,10.72-6.9,30.81,30.81,0,0,0,6.64-9.66,14.41,14.41,0,0,0,.57,1.64,14.57,14.57,0,0,0,3.49,5.09,15.57,15.57,0,0,0,5.27,3.23,19.53,19.53,0,0,0,13.15,0A15.25,15.25,0,0,0,563,283.6a15,15,0,0,0,3.49-5.09,17,17,0,0,0,1.28-6.72V252.36H580v34.56h7.33V252.36h12.63v-6.64Zm-43.05,25.76a22.16,22.16,0,0,1-4.46,7.48,20.44,20.44,0,0,1-6.94,5,22,22,0,0,1-9,1.8,21.71,21.71,0,0,1-9-1.8,20.64,20.64,0,0,1-6.89-5,22.16,22.16,0,0,1-4.46-7.48,27.41,27.41,0,0,1-1.58-9.46A24.91,24.91,0,0,1,484,253.1a22.26,22.26,0,0,1,4.46-7.26A20.51,20.51,0,0,1,495.3,241a22.19,22.19,0,0,1,9-1.76,22.45,22.45,0,0,1,9,1.76,20.32,20.32,0,0,1,6.94,4.86,22.26,22.26,0,0,1,4.46,7.26,24.91,24.91,0,0,1,1.58,8.92A27.41,27.41,0,0,1,524.68,271.48Z" />
            <path class="svg-red-fill" d="M487.4,262.61a18.52,18.52,0,0,1,1.26-7,15.71,15.71,0,0,1,3.49-5.34,15.41,15.41,0,0,1,5.32-3.4,18.71,18.71,0,0,1,6.78-1.19,19.13,19.13,0,0,1,6.85,1.1,15.06,15.06,0,0,1,5.36,3.36,15.43,15.43,0,0,1,3.51,5.32,18.28,18.28,0,0,1,1.27,6.93,17.73,17.73,0,0,1-1.27,6.81,15.6,15.6,0,0,1-3.51,5.27A16.15,16.15,0,0,1,511.1,278a19.15,19.15,0,0,1-6.85,1.28,18.71,18.71,0,0,1-6.78-1.19,15.19,15.19,0,0,1-8.81-8.63A17.71,17.71,0,0,1,487.4,262.61Zm5.94-.37a13.9,13.9,0,0,0,.79,4.74,11.31,11.31,0,0,0,2.23,3.74,10.33,10.33,0,0,0,3.45,2.47,10.67,10.67,0,0,0,4.48.9,10.85,10.85,0,0,0,4.51-.9,10.31,10.31,0,0,0,3.47-2.47A11.31,11.31,0,0,0,514.5,267a13.9,13.9,0,0,0,.79-4.74,12.6,12.6,0,0,0-.79-4.46,11.06,11.06,0,0,0-2.23-3.62,10.23,10.23,0,0,0-3.47-2.44,11.34,11.34,0,0,0-4.51-.88,11.16,11.16,0,0,0-4.48.88,10.25,10.25,0,0,0-3.45,2.44,11.06,11.06,0,0,0-2.23,3.62A12.6,12.6,0,0,0,493.34,262.24Z" />
            <path class="svg-white-fill" d="M496.7,262.53a8.27,8.27,0,0,1,.57-3.13,7.14,7.14,0,0,1,1.57-2.41,7,7,0,0,1,2.39-1.53,9.49,9.49,0,0,1,6.14,0,6.89,6.89,0,0,1,2.41,1.51,7,7,0,0,1,1.58,2.4,8.18,8.18,0,0,1,.57,3.12,7.94,7.94,0,0,1-.57,3.06,6.94,6.94,0,0,1-1.58,2.37,7.13,7.13,0,0,1-2.41,1.55,8.33,8.33,0,0,1-3.09.58,8.61,8.61,0,0,1-3-.53,7,7,0,0,1-2.39-1.53,6.92,6.92,0,0,1-1.57-2.36A7.94,7.94,0,0,1,496.7,262.53Zm2.68-.16a6.34,6.34,0,0,0,.35,2.13,5,5,0,0,0,6.6,2.8,4.79,4.79,0,0,0,1.56-1.12,4.9,4.9,0,0,0,1-1.68,6.34,6.34,0,0,0,.35-2.13,5.74,5.74,0,0,0-.35-2,4.87,4.87,0,0,0-1-1.63,4.6,4.6,0,0,0-1.56-1.1,5.09,5.09,0,0,0-2-.39,5,5,0,0,0-2,.39,4.65,4.65,0,0,0-1.55,1.1,4.87,4.87,0,0,0-1,1.63A5.74,5.74,0,0,0,499.38,262.37Z" />
          </g>

          <g id="svg-comm">
            <path class="svg-white-fill" d="M606,399.89a9.34,9.34,0,0,1,.64-3.52,7.9,7.9,0,0,1,1.77-2.7,7.74,7.74,0,0,1,2.69-1.72,9.43,9.43,0,0,1,3.43-.6,9.59,9.59,0,0,1,3.46.56,7.57,7.57,0,0,1,2.71,1.7,7.84,7.84,0,0,1,1.78,2.69,9.26,9.26,0,0,1,.64,3.51,9,9,0,0,1-.64,3.44,7.89,7.89,0,0,1-1.78,2.67,8.11,8.11,0,0,1-2.71,1.74,9.59,9.59,0,0,1-3.46.65,9.44,9.44,0,0,1-3.43-.6,7.69,7.69,0,0,1-4.45-4.36A9,9,0,0,1,606,399.89Zm3-.18a6.93,6.93,0,0,0,.4,2.39,5.61,5.61,0,0,0,1.13,1.89,5.24,5.24,0,0,0,1.74,1.25,5.46,5.46,0,0,0,2.27.46,5.54,5.54,0,0,0,2.28-.46,5.22,5.22,0,0,0,1.75-1.25,5.62,5.62,0,0,0,1.13-1.89,6.94,6.94,0,0,0,.4-2.39,6.29,6.29,0,0,0-.4-2.26,5.59,5.59,0,0,0-1.13-1.83,5.16,5.16,0,0,0-1.75-1.23,5.67,5.67,0,0,0-2.28-.44,5.59,5.59,0,0,0-2.27.44,5.18,5.18,0,0,0-1.74,1.23,5.58,5.58,0,0,0-1.13,1.83A6.29,6.29,0,0,0,609,399.71Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M686,374.56v37.12h-.14l-24.2-37.12H649.92v10c-.05-.14-.1-.28-.16-.42a34.86,34.86,0,0,0-7.9-11.95,33.66,33.66,0,0,0-12-7.54,42.61,42.61,0,0,0-15.39-2.48,41.91,41.91,0,0,0-15.24,2.68,34.41,34.41,0,0,0-11.95,7.64,35.1,35.1,0,0,0-7.85,12c-.09.23-.17.47-.26.71V374.56H531.27v8h15.31v41.92h8.89V382.6h14.76v41.92h8.89v-9.79c.09.23.17.46.26.69a34.16,34.16,0,0,0,19.79,19.39,41.93,41.93,0,0,0,15.24,2.68,42.64,42.64,0,0,0,15.39-2.89,36.06,36.06,0,0,0,12-7.75,35.06,35.06,0,0,0,7.9-11.85c.06-.14.1-.28.16-.42v9.92h8.89V386.2h.14l24.7,38.32h11.29v-50ZM637.45,410a25,25,0,0,1-5,8.4,23.18,23.18,0,0,1-7.8,5.57,24.64,24.64,0,0,1-10.12,2,24.28,24.28,0,0,1-10.07-2,23.3,23.3,0,0,1-7.75-5.57,24.93,24.93,0,0,1-5-8.4,30.8,30.8,0,0,1-1.77-10.63,27.94,27.94,0,0,1,1.77-10,24.81,24.81,0,0,1,5-8.15,23,23,0,0,1,7.75-5.47,24.84,24.84,0,0,1,10.07-2,25.2,25.2,0,0,1,10.12,2,22.93,22.93,0,0,1,7.8,5.47,24.86,24.86,0,0,1,5,8.15,28,28,0,0,1,1.77,10A30.84,30.84,0,0,1,637.45,410Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M518.63,374.93H511L494.83,412.2c-1.62,2.08-3.6,2.93-5.47,3.88a12.71,12.71,0,0,1-5.81,1.43,16.69,16.69,0,0,1-6.92-1.39,16,16,0,0,1-5.32-3.83,17.12,17.12,0,0,1-3.44-5.77,21.16,21.16,0,0,1-1.22-7.3,19.2,19.2,0,0,1,1.22-6.89,17,17,0,0,1,3.44-5.6,15.83,15.83,0,0,1,5.32-3.76,17.07,17.07,0,0,1,6.92-1.36,14.2,14.2,0,0,1,5.11,1,14.69,14.69,0,0,1,5.25,3.9l6.82-5a21.66,21.66,0,0,0-8-6,24,24,0,0,0-9.29-1.77A28.79,28.79,0,0,0,473,375.52a23.65,23.65,0,0,0-8.21,5.25,24.11,24.11,0,0,0-5.39,8.24,28.52,28.52,0,0,0-1.95,10.75,27.33,27.33,0,0,0,1.95,10.5A23.47,23.47,0,0,0,473,423.6a28.79,28.79,0,0,0,10.47,1.84,25.66,25.66,0,0,0,8-1.25h7.93l4.45-11.27h21.36l4.59,11.27h10ZM506.87,405.4l7.72-20,7.58,20Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M423.53,374.56v37.12h-.14l-24.21-37.12H386.82v31.33a14.23,14.23,0,0,1-.56,3.84,10.82,10.82,0,0,1-1.87,3.71,10,10,0,0,1-3.46,2.79,13.52,13.52,0,0,1-10.66,0,10,10,0,0,1-3.46-2.79,10.79,10.79,0,0,1-1.87-3.71,14.19,14.19,0,0,1-.56-3.84V374.56h-8.89v31.62a21,21,0,0,0,1.52,8.15,17.62,17.62,0,0,0,4.23,6.17,19,19,0,0,0,6.39,3.92,23.79,23.79,0,0,0,15.95,0,19.39,19.39,0,0,0,3.83-1.95v2.05h8.89V386.2h.14l24.7,38.32h11.29v-50Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M595.56,400a20.76,20.76,0,0,1,1.42-7.82,17.55,17.55,0,0,1,3.92-6,17.21,17.21,0,0,1,6-3.82A21,21,0,0,1,614.5,381a21.3,21.3,0,0,1,7.7,1.24,16.83,16.83,0,0,1,6,3.77,17.43,17.43,0,0,1,3.95,6,20.57,20.57,0,0,1,1.42,7.8,19.91,19.91,0,0,1-1.42,7.64,17.53,17.53,0,0,1-3.95,5.92,18,18,0,0,1-6,3.87,21.32,21.32,0,0,1-7.7,1.44,21,21,0,0,1-7.62-1.34,17.08,17.08,0,0,1-9.9-9.69A19.89,19.89,0,0,1,595.56,400Zm6.68-.41a15.4,15.4,0,0,0,.89,5.32,12.47,12.47,0,0,0,2.51,4.2,11.65,11.65,0,0,0,3.87,2.78,12.14,12.14,0,0,0,5,1,12.32,12.32,0,0,0,5.06-1,11.59,11.59,0,0,0,3.9-2.78,12.49,12.49,0,0,0,2.51-4.2,15.42,15.42,0,0,0,.89-5.32,14,14,0,0,0-.89-5,12.43,12.43,0,0,0-2.51-4.08,11.46,11.46,0,0,0-3.9-2.73,12.6,12.6,0,0,0-5.06-1,12.42,12.42,0,0,0-5,1,11.52,11.52,0,0,0-3.87,2.73,12.41,12.41,0,0,0-2.51,4.08A14,14,0,0,0,602.25,399.57Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M246,348.83q-10.38-11.18-20.1-14.91a54.32,54.32,0,0,0-19.57-3.73,65.31,65.31,0,0,0-26.49,5.19,60.58,60.58,0,0,0-20.37,14.38,65.21,65.21,0,0,0-13.18,21.43,73.47,73.47,0,0,0-4.66,26.36,81,81,0,0,0,4.66,28,65.49,65.49,0,0,0,13.18,22.1,61.24,61.24,0,0,0,20.37,14.65,63.84,63.84,0,0,0,26.49,5.33,48.65,48.65,0,0,0,22.23-5.46q10.78-5.45,20.1-17.44l27.69,19.7q-12.78,17.57-31.15,25.56a97.06,97.06,0,0,1-39.13,8A110.23,110.23,0,0,1,166,490.87a89.82,89.82,0,0,1-52-51q-7.46-18.1-7.46-40.2,0-22.63,7.46-41.13A92.26,92.26,0,0,1,134.59,327,90.51,90.51,0,0,1,166,306.9a110.19,110.19,0,0,1,40.07-7.06,91.87,91.87,0,0,1,35.54,6.79q16.37,6.79,30.48,23Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M441.57,374.93h8.77v49.25h-8.77Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M333.07,374.56l-13.48,35-13.34-35H288l-13.48,35-13.34-35H247.67v10.66a42.5,42.5,0,0,0-1.49-4.36,41,41,0,0,0-9.28-14,39.57,39.57,0,0,0-14.16-8.87A50.09,50.09,0,0,0,204.64,355a49.26,49.26,0,0,0-17.91,3.15,40.45,40.45,0,0,0-14,9,41.26,41.26,0,0,0-9.22,14.11,48.8,48.8,0,0,0-3.33,18.39,46.77,46.77,0,0,0,3.33,18,40.16,40.16,0,0,0,23.27,22.79,49.3,49.3,0,0,0,17.91,3.15,50.13,50.13,0,0,0,18.09-3.39,42.39,42.39,0,0,0,14.16-9.11,41.22,41.22,0,0,0,9.28-13.93,41.71,41.71,0,0,0,1.49-4.29v11.66h8.47V383h.14l15.17,41.49h6.21l15-41.13v41.13h8.74V383.39l15,41.13h6.21L337.87,383H338v41.49h8.47v-50ZM231.72,411.2a29.36,29.36,0,0,1-5.89,9.88,27.25,27.25,0,0,1-9.16,6.55,29,29,0,0,1-11.9,2.38,28.54,28.54,0,0,1-11.84-2.38,27.39,27.39,0,0,1-9.11-6.55,29.31,29.31,0,0,1-5.89-9.88,36.21,36.21,0,0,1-2.08-12.5,32.85,32.85,0,0,1,2.08-11.78,29.17,29.17,0,0,1,5.89-9.58,27.09,27.09,0,0,1,9.11-6.43,29.2,29.2,0,0,1,11.84-2.32,29.63,29.63,0,0,1,11.9,2.32,27,27,0,0,1,9.16,6.43,29.22,29.22,0,0,1,5.89,9.58,32.89,32.89,0,0,1,2.08,11.78A36.25,36.25,0,0,1,231.72,411.2Z" transform="translate(-68 -66)"/>
            <path class="svg-red-fill" d="M182.47,399.48a24.4,24.4,0,0,1,1.67-9.19,20.63,20.63,0,0,1,4.61-7.05,20.23,20.23,0,0,1,7-4.49,24.63,24.63,0,0,1,9-1.58,25,25,0,0,1,9,1.46,19.78,19.78,0,0,1,7.08,4.43,20.49,20.49,0,0,1,4.64,7,24.18,24.18,0,0,1,1.67,9.17,23.4,23.4,0,0,1-1.67,9,20.61,20.61,0,0,1-4.64,7,21.2,21.2,0,0,1-7.08,4.55,25.06,25.06,0,0,1-9,1.7,24.65,24.65,0,0,1-9-1.58,20.08,20.08,0,0,1-11.64-11.4A23.39,23.39,0,0,1,182.47,399.48Zm7.86-.48a18.11,18.11,0,0,0,1,6.25,14.65,14.65,0,0,0,2.95,4.94,13.7,13.7,0,0,0,4.55,3.27,14.27,14.27,0,0,0,5.92,1.19,14.48,14.48,0,0,0,6-1.19,13.62,13.62,0,0,0,4.58-3.27,14.68,14.68,0,0,0,2.95-4.94,18.13,18.13,0,0,0,1-6.25,16.44,16.44,0,0,0-1-5.89,14.61,14.61,0,0,0-2.95-4.79,13.48,13.48,0,0,0-4.58-3.21,14.81,14.81,0,0,0-6-1.16,14.6,14.6,0,0,0-5.92,1.16,13.54,13.54,0,0,0-4.55,3.21,14.58,14.58,0,0,0-2.95,4.79A16.43,16.43,0,0,0,190.32,399Z" transform="translate(-68 -66)"/>
            <path class="svg-white-fill" d="M194.76,399.38a11,11,0,0,1,.75-4.14,9.28,9.28,0,0,1,2.08-3.17,9.1,9.1,0,0,1,3.16-2,11.08,11.08,0,0,1,4-.71,11.27,11.27,0,0,1,4.07.66,8.9,8.9,0,0,1,3.19,2,9.22,9.22,0,0,1,2.09,3.16,10.88,10.88,0,0,1,.75,4.12,10.53,10.53,0,0,1-.75,4,9.27,9.27,0,0,1-2.09,3.13,9.54,9.54,0,0,1-3.19,2,11.28,11.28,0,0,1-4.07.76,11.09,11.09,0,0,1-4-.71,9,9,0,0,1-5.24-5.13A10.52,10.52,0,0,1,194.76,399.38Zm3.54-.21a8.15,8.15,0,0,0,.47,2.81,6.59,6.59,0,0,0,1.33,2.22,6.16,6.16,0,0,0,2,1.47,6.42,6.42,0,0,0,2.66.54,6.52,6.52,0,0,0,2.68-.54,6.13,6.13,0,0,0,2.06-1.47,6.61,6.61,0,0,0,1.33-2.22,8.16,8.16,0,0,0,.47-2.81,7.4,7.4,0,0,0-.47-2.65,6.58,6.58,0,0,0-1.33-2.16,6.06,6.06,0,0,0-2.06-1.45,6.67,6.67,0,0,0-2.68-.52,6.57,6.57,0,0,0-2.66.52,6.09,6.09,0,0,0-2,1.45,6.56,6.56,0,0,0-1.33,2.16A7.39,7.39,0,0,0,198.29,399.16Z" transform="translate(-68 -66)"/>
          </g>

          <g id="svg-pat">
            <path class="svg-white-fill" d="M646.89,435.1v9H629.76V491h-9.95V444.1h-16.6V491H590.59L563,448.13h-.16V491h-9.95V435.1H566l27.07,41.52h.16V435.1h53.62Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-white-fill" d="M541.24,482v9H502.58V444.57h-.16l-17,46.41H478.5l-17-46.41h-.16V491H451.9V435.1h15.16L482,474.25l15.08-39.15h42.77v9H512.75v13.73H538.4v9H512.75V482Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-white-fill" d="M442.1,466.6a15.83,15.83,0,0,0-4.89-4.81,26.05,26.05,0,0,0-6.39-2.84q-3.47-1-6.39-2.13a19.36,19.36,0,0,1-4.89-2.6,5.12,5.12,0,0,1-2-4.34,6.49,6.49,0,0,1,.79-3.28,6.6,6.6,0,0,1,2.09-2.25,9.12,9.12,0,0,1,2.92-1.26,14.11,14.11,0,0,1,3.35-.39,14.75,14.75,0,0,1,5.56,1.07,9.18,9.18,0,0,1,4.22,3.35l7.26-7.66A19.87,19.87,0,0,0,436.3,435a27.64,27.64,0,0,0-8.56-1.34,28.33,28.33,0,0,0-7.58,1,20.34,20.34,0,0,0-6.59,3.12l-.27.22a19.57,19.57,0,0,0-5.79-3,27.64,27.64,0,0,0-8.56-1.34,28.33,28.33,0,0,0-7.58,1,20.34,20.34,0,0,0-6.59,3.12,16.25,16.25,0,0,0-4.66,5.25,15.05,15.05,0,0,0-1.78,7.5,14.11,14.11,0,0,0,2,7.93,15.54,15.54,0,0,0,4.89,4.74,26.86,26.86,0,0,0,6.39,2.8q3.47,1,6.39,2.17a17.49,17.49,0,0,1,4.89,2.8,5.84,5.84,0,0,1,2,4.74,6.44,6.44,0,0,1-.87,3.35,7.42,7.42,0,0,1-2.29,2.41,10.73,10.73,0,0,1-3.16,1.42,13.16,13.16,0,0,1-3.47.47,13.42,13.42,0,0,1-6.28-1.54,12.52,12.52,0,0,1-4.78-4.3l-7.5,7.26a19.3,19.3,0,0,0,8.29,5.84,29.35,29.35,0,0,0,10,1.74,27.26,27.26,0,0,0,7.81-1.1,19.35,19.35,0,0,0,6.25-3.13,18.8,18.8,0,0,0,4.71,2.5,29.35,29.35,0,0,0,10,1.74,27.26,27.26,0,0,0,7.81-1.1A19.28,19.28,0,0,0,438,488a16,16,0,0,0,4.46-5.57,17.53,17.53,0,0,0,1.66-7.85A14.32,14.32,0,0,0,442.1,466.6Zm-9.31,12.51a7.42,7.42,0,0,1-2.29,2.41,10.73,10.73,0,0,1-3.16,1.42,13.16,13.16,0,0,1-3.47.47,13.42,13.42,0,0,1-6.28-1.54,12.68,12.68,0,0,1-3-2.16,19.42,19.42,0,0,0,.67-5.14,14.32,14.32,0,0,0-2-8,15.83,15.83,0,0,0-4.89-4.81,26.05,26.05,0,0,0-6.39-2.84q-3.47-1-6.39-2.13a19.36,19.36,0,0,1-4.89-2.6,5.12,5.12,0,0,1-2-4.34,6.49,6.49,0,0,1,.79-3.28,6.6,6.6,0,0,1,2.09-2.25,9.12,9.12,0,0,1,2.92-1.26,14.11,14.11,0,0,1,3.35-.39,14.75,14.75,0,0,1,5.56,1.07,9.18,9.18,0,0,1,4,3.13,17.68,17.68,0,0,0-.38,3.7,14.11,14.11,0,0,0,2,7.93,15.54,15.54,0,0,0,4.89,4.74,26.86,26.86,0,0,0,6.39,2.8q3.47,1,6.39,2.17a17.49,17.49,0,0,1,4.89,2.8,5.84,5.84,0,0,1,2,4.74A6.44,6.44,0,0,1,432.78,479.11Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-white-fill" d="M332.08,435.1h37v9H342v13.73h25.65v9H342V482h28.49v9H332.08Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-red-fill" d="M464.42,306.59h26.66l54.77,83.59h.32V306.59h20.12V419.11H540.74l-55.88-86.29h-.32v86.29H464.42Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-red-fill" d="M184.74,506.19v-127h22.33a173.31,173.31,0,0,0,26.67-2,64.25,64.25,0,0,0,22.45-7.57,40.82,40.82,0,0,0,15.38-15.75q5.7-10.17,5.71-26.79,0-14.64-5.34-24.44A42.7,42.7,0,0,0,257.68,287,60.31,60.31,0,0,0,237,278.73a118.45,118.45,0,0,0-24.44-2.48h-59V520.38h.69v.28H647.33V506.19Zm0-203.14h22.33a116.88,116.88,0,0,1,12.9.75,37.71,37.71,0,0,1,12.16,3.35,23.06,23.06,0,0,1,8.93,7.44q3.47,4.84,3.47,13,0,8.93-3.84,13.89a23.54,23.54,0,0,1-9.92,7.44,42.62,42.62,0,0,1-13.27,3q-7.2.5-13.89.49H184.74Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-red-fill" d="M412.76,306.59h20.12V419.11H412.76Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-white-fill" d="M219.85,435.1h8.6L252.52,491H241.16l-5.21-12.79H211.72L206.66,491H195.53Zm12.63,34.57-8.6-22.73-8.76,22.73Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-red-fill" d="M320.94,306.59h17.4L387,419.11H364l-10.54-25.75h-49l-10.22,25.75H271.76Zm25.55,69.61-17.4-45.77L311.36,376.2Z" transform="translate(-153.48 -276.25)"/>
            <path class="svg-white-fill" d="M321.55,466.6a15.83,15.83,0,0,0-4.89-4.81,26.05,26.05,0,0,0-6.39-2.84q-3.47-1-6.39-2.13a19.36,19.36,0,0,1-4.89-2.6,5.12,5.12,0,0,1-2-4.34,6.49,6.49,0,0,1,.79-3.28,6.6,6.6,0,0,1,2.09-2.25,9.12,9.12,0,0,1,2.92-1.26,14.11,14.11,0,0,1,3.35-.39,14.75,14.75,0,0,1,5.56,1.07,9.18,9.18,0,0,1,4.22,3.35l7.26-7.66a19.87,19.87,0,0,0-7.46-4.42,27.64,27.64,0,0,0-8.56-1.34,28.33,28.33,0,0,0-7.58,1,20.34,20.34,0,0,0-6.59,3.12l-.27.22a19.57,19.57,0,0,0-5.79-3,27.64,27.64,0,0,0-8.56-1.34,28.33,28.33,0,0,0-7.58,1,20.34,20.34,0,0,0-6.59,3.12,16.25,16.25,0,0,0-4.66,5.25,15.05,15.05,0,0,0-1.78,7.5,14.11,14.11,0,0,0,2,7.93,15.54,15.54,0,0,0,4.89,4.74,26.86,26.86,0,0,0,6.39,2.8q3.47,1,6.39,2.17a17.49,17.49,0,0,1,4.89,2.8,5.84,5.84,0,0,1,2,4.74,6.44,6.44,0,0,1-.87,3.35,7.42,7.42,0,0,1-2.29,2.41,10.73,10.73,0,0,1-3.16,1.42,13.16,13.16,0,0,1-3.47.47,13.42,13.42,0,0,1-6.28-1.54,12.52,12.52,0,0,1-4.78-4.3l-7.5,7.26a19.3,19.3,0,0,0,8.29,5.84,29.35,29.35,0,0,0,10,1.74,27.26,27.26,0,0,0,7.81-1.1,19.35,19.35,0,0,0,6.25-3.13,18.8,18.8,0,0,0,4.71,2.5,29.35,29.35,0,0,0,10,1.74,27.26,27.26,0,0,0,7.81-1.1,19.28,19.28,0,0,0,6.51-3.32,16,16,0,0,0,4.46-5.57,17.53,17.53,0,0,0,1.66-7.85A14.32,14.32,0,0,0,321.55,466.6Zm-9.31,12.51a7.42,7.42,0,0,1-2.29,2.41,10.73,10.73,0,0,1-3.16,1.42,13.16,13.16,0,0,1-3.47.47,13.42,13.42,0,0,1-6.28-1.54,12.68,12.68,0,0,1-3-2.16,19.43,19.43,0,0,0,.67-5.14,14.32,14.32,0,0,0-2-8,15.83,15.83,0,0,0-4.89-4.81,26.05,26.05,0,0,0-6.39-2.84q-3.47-1-6.39-2.13a19.36,19.36,0,0,1-4.89-2.6,5.12,5.12,0,0,1-2-4.34,6.49,6.49,0,0,1,.79-3.28,6.6,6.6,0,0,1,2.09-2.25,9.12,9.12,0,0,1,2.92-1.26,14.11,14.11,0,0,1,3.35-.39,14.75,14.75,0,0,1,5.56,1.07,9.18,9.18,0,0,1,4,3.13,17.68,17.68,0,0,0-.38,3.7,14.11,14.11,0,0,0,2,7.93,15.54,15.54,0,0,0,4.89,4.74,26.86,26.86,0,0,0,6.39,2.8q3.47,1,6.39,2.17a17.49,17.49,0,0,1,4.89,2.8,5.84,5.84,0,0,1,2,4.74A6.44,6.44,0,0,1,312.24,479.11Z" transform="translate(-153.48 -276.25)"/>
          </g>

          <g id="circle">
            <circle #circle class="svg-circle" cx="333" cy="333" r="325.8" [ngClass]="mode" />
          </g>
        </svg>
      </div>
    </div>
    
    <div class="center-wrap {{ mode }}">
      <div #btns class="center-inner" [ngStyle]="{'pointer-events': menuState === 'hide' ? 'none' : 'auto' }">
        <ppt-circle-btns></ppt-circle-btns>
      </div>
    </div>

</div>
