<div class="content-wrap">

  <h1>Terms of Use</h1>

  <div class="inner-wrap">

    <h3>Pinpoint End User License Agreement</h3>
        <p>Entering into this Agreement: This End User License Agreement (the <b>“Agreement”</b> or “EULA”) constitutes a valid and binding agreement between the owners of the Pinpoint web application, HPC International, Inc. (<b>“HPC”</b>) and Klein Buendel (<b>“KB”</b>), and <b>You</b>, as a user, for the use of the Pinpoint web application intended for patients with sickle cell disease to learn about, track and share information about their pain (the <b>"Program"</b>).</p>

        <h3>Terms & Conditions</h3>
        <p>The Pinpoint web application is intended as an informational reference and mobile-friendly tool for patients with sickle cell disease to track their sickle cell pain and should never replace, countermand, or conflict with the expert advice given to users by their medical provider. The owners of Pinpoint disclaim all liability in connection with the specific personal use of any and all information provided in or shared through this web application. All rights reserved. No part of the Pinpoint web application may be shared, copied, replicated, reproduced, resold, or transmitted in any form or by any means for any commercial or non-commercial purpose, whether electronic or mechanical, including photocopying, recording, or any information storage or retrieval systems, including digital systems. Any violation of this clause at any time for any reason will be subject to the maximum legal consequences, fines and other penalties allowed by law.</p>
        <p>By accessing and using the Program, You explicitly agree to comply with and be bound by the following terms and conditions. You hereby agree and acknowledge that this Agreement covers all Your use of the Program. Furthermore, by continuing to use the Program, You agree to be bound by the terms of this Agreement and any new versions hereof which shall be published online at www.pinpointscd.com.</p>
        <p>You hereby agree to the use of electronic communication in order to use the Program and in order to voluntarily share your personal or patient health information with any parent(s), guardian(s), or health care provider(s)  You choose to set up in the Program. You also hereby agree to the electronic delivery of notices, policies and records of transactions initiated or completed through the Program. Furthermore, You hereby waive any rights or requirements under any laws or regulations in any jurisdiction which require an original (non-electronic) signature or delivery or retention of non-electronic records for any part of this Agreement and the terms and conditions herein, to the extent permitted under applicable mandatory law. By clicking the “Agree” button following this Agreement, You hereby agree and consent to all terms and conditions in this Agreement, and you also agree to all the Terms of Use, which may be viewed 24/7 online  in their most current version at www.pinpointscd.com and which may be updated by the owners from time to time with or without prior notice or direct notification to You.</p>

        <ol type="1">
          <li><b>The Program</b>
            <ol type="a">
              <li><span class="underline">Program License</span>. Subject to the terms and conditions of this Agreement, HPC grants You a limited, revocable, non-exclusive, non-transferable, non-assignable right to access and use the Program for a 1-Year Subscription Term as specified below. The right is personal to You and You agree not to reproduce, share, duplicate, copy, sell, trade, resell or exploit for any commercial or non-commercial purposes, any portion of the Program or access to the Program. If You use the Program in a manner that exceeds the scope of this license or You breach any of the terms of this Agreement, HPC may terminate this license and the Agreement. All intellectual property rights in and to all aspects of the Program and its contents, including but not limited to  the technology used to provide the Program, are the sole and exclusive property of the owners, HPC and KB. HPC will provide You with the necessary information required to access and use the Program, and You will treat that information as confidential information.</li><br>
              <li><span class="underline">License Restrictions</span>. You may not use the Program in any manner or for any purpose other than as expressly permitted by this Agreement. You may not, and may not attempt to (i) modify, alter, tamper with, repair or otherwise create derivative works of the Program or any software included in the Program, (ii) reverse engineer, disassemble, or decompile the Program or apply any other process or procedure to derive the source code of any software included in the Program, (iii) access or use the Program in a way intended to exceed usage limits, quotas, unique logons or seats, or (iv) resell or sublicense the Program.</li><br>
              <li><span class="underline">Internet Access</span>. In order to use the Program, You must have access to the Internet and You are solely responsible to pay any fees associated with that Internet access. You are responsible for obtaining and maintaining all software, telecommunications, and computer equipment or mobile device needed for Your access to and use of the Program, and for all related fees.</li><br>
              <li><span class="underline">Your Account</span>. To access and use the Program, You must provide HPC with accurate and complete contact and payment information, including a valid email address, your full name, billing and payment-related information, as well as other information required by HPC. You must immediately update Your HPC Account if any of Your HPC Account information changes.</li><br>
              <li><span class="underline">Security</span>. You will be prompted to select a password when You open an HPC Account. You will be solely responsible for keeping Your password confidential and for all use of Your password and HPC Account, including any unauthorized use. You must notify HPC immediately if You believe Your HPC Account has been accessed, hacked, or otherwise used by any unauthorized person or entity other than You. If You become aware of any other breach or attempted breach of the security of Your HPC Account or the Program, You agree to notify HPC immediately. Except to the extent caused by a breach of this Agreement by HPC or KB, the owners of Pinpoint shall not be responsible for unauthorized access to your HPC Account.   You may terminate Your HPC Account and this Agreement in accordance with Section 3. <br><br> The Program stores and shares (with Your permission) some patient health information between You and your parent(s) or guardian(s) who have been given access to the Pinpoint web application by You, and between You and your health care provider(s) who have been given access to the Pinpoint web application by You, and You agree to this and consent to storing and sharing the information you enter into the Pinpoint web application with your parent(s) or guardian(s) and your health care provider(s) through the Pinpoint web application. KB uses industry best practices and complies with HHS guidelines regarding the security of data that contains patient health information (<b>“PHI/ePHI”</b>) and KB uses encryption of all data used in the Program. The Pinpoint web application does not transmit PHI/ePHI via email or text message. KB reserves the right to make changes over time with its data security measures without prior notice to You, but any such changes shall be consistent with and no less stringent than the specific data security and privacy protection measures as described below in e(i).
                <ol type="i"><br>
                  <li>For data security and privacy purposes, all traffic flows through a hardware firewall and is directed to a server farm. Network hardware is locked in a room with restricted access. Servers are virtualized and running on Dell PowerEdge hardware utilizing RAID for redundancy. Battery backup prevents data loss in the event of power outage. All data is backed-up onsite and to the cloud. Cloud backups are encrypted and immutable. Windows AD provides network access security and is federated with AAD in the cloud. Two-factor authentication is required. All systems are protected with firewalls and Microsoft Defender Endpoint with EDR features. Active monitoring and alerts allow for immediate response to any breaches. All participant information is protected using SSL encryption. KB uses certificates provided by either GoDaddy.com or LetsEncrypt.org.</li><br>
                  <li>FTC Health Breach Notification Rule (16 C.F.R. § 318) (2009)  requires vendors of personal health records and related entities (HPC) to notify consumers (You) following a breach involving unsecured information. In addition, if a service provider to one of these entities has a breach, vendors must notify the entity, which in turn must notify consumers (You), and in large breaches involving 500 or more people, the media.</li>
                </ol>
                <br>The owners agree to keep your PHI/ePHI confidential and to treat your information with the same level of care, anonymity, and security as they would treat their own PHI/ePHI. The owners agree that only You may choose to share your PHI/ePHI, and as such the owners will never share or disclose your PHI/ePHI with any person or entity without your prior written consent except as may be required by law.
              </li><br>
              <li><span class="underline">Features</span>. At any time and in its sole discretion, HPC and KB may make new features available or change or remove existing features to enhance the Program. Unless expressly stated otherwise, any changes in Program features that HPC and KB make will be subject to the terms of this Agreement.</li><br>
              <li>The owners reserve the right, in their sole discretion, to add, delete or modify any of the terms contained in this Agreement, at any time, by posting the revised terms and conditions agreement on the HPC web site located at www.pinpointscd.com (<b>“Site”</b>). If any modification is unacceptable to You, Your only recourse is to discontinue Your use of the Program. Your continued use of the Program following posting of a change notice or new agreement on the Site will constitute binding acceptance of the revised terms and conditions, as amended.</li>
            </ol>
          </li><br>
          <li><b>Fees</b>. Fees for use of the Program shall initially be the fees offered to You at the time You sign up to use the Program. The fees for the Program may be adjusted by HPC from time-to-time.</li><br>
          <li><b>Term and Termination</b>. This Agreement will commence upon Your online acceptance of and agreement to this EULA (the <b>“Commencement Date”</b>) and will remain effective for a 1-Year period (the <b>“Subscription Term”</b>),unless earlier terminated by HPC. HPC may terminate this Agreement with immediate effect if Your use of the Program violates any of the Terms of Use as outlined herein. In the event of early termination due to a violation of the Terms of Use, HPC shall provide advance notice via email to You, no refund, neither in part nor in full, shall be offered, and you may be blocked from subscribing to the Program again in the future.</li><br>
          <li><b>Disclaimer</b>. THE OWNERS PROVIDE THE PROGRAM ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE OWNERS DO NOT WARRANT THAT THE PROGRAM OR ITS USE WILL: (i) MEET YOUR REQUIREMENTS; (ii) OPERATE ON AN UNINTERRUPTED BASIS; OR (iii) BE FREE OF ERRORS OR INACCURACIES. THE OWNERS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. THIS SECTION SHALL SURVIVE IN THE EVENT OF TERMINATION OF THIS AGREEMENT.</li><br>
          <li><b>Limitation of Liability</b>. THE OWNERS WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS OR INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THE USE OF THE PROGRAM, BASED ON ANY CAUSE OF ACTION, EVEN IF THE OWNERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE OWNERS WILL NOT BE LIABLE FOR ANY OF YOUR OBLIGATIONS OR RESPONSIBILITIES TO PAY ANY TAXES BASED ON YOUR USE OF THE PROGRAM. IN NO EVENT WILL THE OWNERS’ LIABILITY TO YOU IN CONNECTION WITH THIS AGREEMENT OR THE PROGRAM EXCEED THE AMOUNT OF THE FEES PAID BY YOU TO HPC PURSUANT TO THIS AGREEMENT DURING THE THREE (3) MONTHS IMMEDIATELY PRECEDING THE EVENT THAT GIVES RISE TO SUCH LIABILITY. THIS SECTION SHALL SURVIVE IN THE EVENT OF TERMINATION OF THIS AGREEMENT.</li><br>
          <li><b>Support</b>. The owners do not provide live or 24/7 support for the Program. Feedback may be sent to info@pinpointscd.com. The owners have no obligation to respond to any email sent to this account, unless the email relates to You notifying the owners of a security-related breach of the Terms of Use of the Program in accordance with Section 1(e) above.</li><br>
          <li><b>Modifications to and Discontinuation of Program</b>. HPC reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Program (or any part thereof) with or without notice. You agree that HPC shall not be liable to You or to any third party for any modification, suspension or discontinuance of the Program.</li><br>
          <li><b>Notice</b>. All notices to You will be in writing and will be made via email. HPC may also broadcast notices or messages through the Site to inform You of changes to the Program, or other matters of importance; such broadcasts shall constitute notice to You. Notifications about your HPC Account or Pinpoint User Account will be sent via email to the email address you have provided to HPC. It is important that you update HPC if your email address changes, so that HPC is able to effectively communicate with you about any notices, updates or other information related to your subscription to or use of the Program.</li><br>
          <li><b>Dispute Resolution</b>. This Agreement will be construed under the laws of the State of Illinois, without giving effect to any conflicts of laws principles that require the application of the law of a different jurisdiction. Except in cases where You are in breach of any provision of this Agreement, any dispute between the parties relating to the subject matter of this Agreement will be resolved by arbitration. The parties shall agree on a single arbitrator, who shall resolve the dispute consistent with the published rules of the American Arbitration Association. Any arbitration hearing will be held in Illinois. This Agreement does not require that the American Arbitration Association, or any other established dispute resolution body, be involved in the resolution of the dispute. For avoidance of doubt, in cases where You are in breach of any provision of this Agreement, HPC may elect to arbitrate the dispute or bring a proceeding in the state or federal courts located in Illinois, and You hereby consent to such venue. This section shall survive in the event of termination of this Agreement.</li><br>
          <li><b>Force Majeure</b>. If the performance of any obligation under this Agreement, except the making of payments, is prevented or interfered with by a force majeure (any act or condition whatsoever beyond the reasonable control of and not occasioned by the fault or negligence of the affected party, including, without limitation, acts of God, acts of terrorism, acts of nature or of a public enemy, acts of a federal government or any state or political subdivision thereof, internet brownouts, pandemics, fires, floods, explosions, wars, or other catastrophes; labor disturbances; freight embargos; or delays of a supplier or subcontractor due to such causes), the party so affected shall be excused from such performance to the extent of such prevention or interference.</li><br>
          <li><b>Miscellaneous</b>. Except to the extent that You expressly authorize and empower HPC to act as Your agent in the Agreement, the parties are independent contractors and NOTHING in this Agreement shall be construed to create a partnership, joint venture, patient-doctor, or employment relationship between the parties. This Agreement, including all the terms and conditions herein, sets forth the entire agreement between the parties and supersedes any and all prior proposals, agreements or communications, written or oral or implied, of the parties with respect to the subject matter hereof. No failure or delay by any party in exercising any right hereunder will operate as a waiver thereof and all waivers must be in writing and executed by all parties. This Agreement will be binding on the parties, their successors and permitted assigns. If any provision of this Agreement is found invalid or unenforceable by an arbitrator or a court of competent jurisdiction, the remaining portions shall remain in full force and effect.</li>
        </ol>
        **END**

  </div>
</div>
