import { SuperModalState } from './super-modal.reducer';

export interface State {
    superModal: SuperModalState;
}
export const getShow = state => state.show;

export const getTitle = state => state.title;

export const getText = state => state.text;

export const getHTML = state => state.html;

export const getButtons = state => state.buttons;

export const getInserted = state => state.inserted;

export const getImage = state => state.image;

export const getFirstLoad = state => state.firstload;

