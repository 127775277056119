import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewEncapsulation } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { MenuData } from '../../models';
import { gsap } from 'gsap';

@Component({
  selector: 'ppt-top-nav-btns',
  templateUrl: './top-nav-btns.component.html',
  styleUrls: ['./top-nav-btns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopNavBtnsComponent implements OnInit {

  @ViewChildren('btns') btns: QueryList<ElementRef>;

  menuItems: MenuData[];
  btnsArr: HTMLElement[];

  constructor(private service: MenuService) { }

  ngOnInit() {
    this.service.showCircleMenu$.subscribe(b => {
      if (b) {
        this.destroy();
      } else {
        this.create();
      }
    });
  }

  private create() {
    const newMenu = this.service.getParentMenu(this.service.curMenuData$.getValue(), false);
    if (!newMenu || this.menuItems === newMenu) return;
    this.menuItems = newMenu;
    if (this.menuItems) {
      setTimeout(() => {
        this.btnsArr = this.btns.toArray().map(elRef => elRef.nativeElement);
        gsap.fromTo(this.btnsArr,
          { scale: 0.6, transformOrigin: 'center center' },
          { autoAlpha: 1, scale: 1, duration: 0.4, ease: 'back.out', stagger: 0.08 });
      }, 450);
    }
  }

  private destroy() {
    setTimeout(() => {
      this.menuItems = undefined;
    }, 200);
  }

}
