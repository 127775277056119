<div class="content-wrap">

  <h1>Project Info</h1>

  <div class="inner-wrap">

    <h2>About the Study:</h2>

    <p>
      The purpose of the Pinpoint web app is to provide adolescents with Sickle Cell Disease (SCD) a place to record their pain, learn about SCD, and find new ways to communicate about it. We have developed a pain assessment tool to describe and categorize specific types of pain experienced by adolescents with sickle cell disease. Users can send pain reports straight to their parent or healthcare provider, if desired. All data stored in this web app is confidential.
    </p>

    <p>
      This web app was created through a study conducted by Klein Buendel, Inc., a public health communications research company located in Golden, CO, and Hilton Publishing, the publisher of the popular Hope & Destiny sickle cell book series and parent company of EMIC Health. Funding for the research study was provided by the National Institute on Minority Health and Health Disparities (NIH).
    </p>

  </div>
</div>
