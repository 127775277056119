import { trigger, animate, transition, style, state } from '@angular/animations';

export const slideUpAnimation =
  trigger('slideUpAnimation', [
    state('hide', style({
      transform: 'translateY(-100%)'
    })),
    state('show', style({
      transform: 'translateY(0%)'
    })),
    transition('hide => show', animate('500ms ease-out')),
    transition('show => hide', animate('500ms ease-out')),
  ]);
