import { Component } from '@angular/core';

@Component({
  selector: 'ppt-eula-info',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent {

  constructor() { }

}
