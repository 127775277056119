import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService } from 'src/app/core/services/menu.service';
import { MenuData } from 'src/app/models';


@Component({
  selector: 'ppt-menus-shell',
  templateUrl: './menus-shell.component.html'
})
export class MenusShellComponent implements OnInit {

  backUrl: string;
  circleMenuMode = 'login'; // login || menu
  circleMenuShow = true;
  logoID = 'home';

  constructor(
    private service: MenuService,
    private router: Router
    ) { }

  ngOnInit() {
    this.service.curMenuData$.subscribe(dta => this.onCurrentData(dta));
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.onNavEnd(e);
      }
    });
  }

  private onNavEnd(e: NavigationEnd) {
    const isRegistrationPage = e.urlAfterRedirects.includes('/register');
    this.service.setCurMenuData(e.urlAfterRedirects);
    /* console.log('MenusShellComponent.onNavEnd() url', e.url, e.urlAfterRedirects); */
    //
    if (e.urlAfterRedirects.includes('/login') || isRegistrationPage) {
      this.circleMenuMode = 'login';
      this.logoID = 'home';
    } else {
      this.circleMenuMode = 'menu';
    }
    //
    if (isRegistrationPage) {
      this.circleMenuShow = false;
    }
  }

  private onCurrentData(dta: MenuData) {
    /* console.log('MenusShellComponent.onCurrentData', dta); */
    if (dta) {
      this.backUrl = dta.back;
      /* console.log('backUrl =', this.backUrl); */
      if (dta.menu) {
        if (dta.id) this.logoID = dta.id;
        this.circleMenuShow = true;
      } else if (!dta.menu) {
        this.circleMenuShow = false;
      }
    } else {
      this.circleMenuShow = true;
    }
    this.service.showCircleMenu$.next(this.circleMenuShow);
  }

}
