<div class="content-wrap">

  <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
    <label for="username">username</label>
    <input type="text" 
      name="username"
      [(ngModel)]="creds.username"
      #username="ngModel"
      placeholder="Enter username / email" 
      required 
      autocorrect="off" 
      autocapitalize="off"
      spellcheck="false" />

    <label for="password">password</label>
    <input type="password"
      name="password"
      placeholder="Enter password"
      [(ngModel)]="creds.password"
      #password="ngModel"
      required />

    <kb-button id="login-submit" type="submit" text="Login" icon="check" class="red" [disabled]="!loginForm.valid">
    </kb-button>
  </form>

  <div class="lower">
    <p>Don't have an account?</p>
    <kb-button text="Create Account" icon="add" class="red" [routerLink]="['/register/creds']" queryParamsHandling="preserve"></kb-button>
  </div>

</div>

<kb-super-modal></kb-super-modal>

<div style="display: none;">
  <form id="email-form" #formEl #emailForm="ngForm" (ngSubmit)="onEmailSubmit()">
    <label for="email">Enter email used to create account.</label>
    <input type="email" name="email" [(ngModel)]="emailData.email" #email="ngModel" placeholder="Enter email" required />
    <div class="btn-wrap">
      <kb-button id="close-btn" type="button" text="Cancel" icon="close" (click)="onClose()"></kb-button>
      <kb-button id="email-submit" type="submit" text="Submit" icon="check" [disabled]="!emailForm.valid"></kb-button>
    </div>
  </form>
</div>
