import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { CircleMenuComponent } from './circle-menu/circle-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { MenusShellComponent } from './menus-shell/menus-shell.component';
import { CircleMenuBtnsComponent } from './circle-menu-btns/circle-menu-btns.component';
import { TopNavBtnsComponent } from './top-nav-btns/top-nav-btns.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { BuddyModule } from '../buddy/buddy.module';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { EulaComponent } from './eula/eula.component';
import { TechSupportComponent } from './tech-support/tech-support.component';



@NgModule({
  declarations: [
    CircleMenuComponent,
    UserMenuComponent,
    TopMenuComponent,
    MenusShellComponent,
    CircleMenuBtnsComponent,
    TopNavBtnsComponent,
    LoginComponent,
    HomeComponent,
    ProjectInfoComponent,
    EulaComponent,
    TechSupportComponent
  ],
  exports: [
    CircleMenuComponent,
    UserMenuComponent,
    TopMenuComponent,
    MenusShellComponent,
    CircleMenuBtnsComponent,
    LoginComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    BuddyModule
  ]
})
export class CoreModule { }
