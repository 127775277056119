<nav class="user-menu" [@userMenuState]="state" (@userMenuState.done)="animEnd($event)" [attr.data-menu-state]="state">
  <ul>
    <li class="user-name"><span>{{username}}</span><span *ngIf="userType === 'reg'" class="points">{{points | numberComma}}</span></li>
    <li *ngIf="userType === 'reg'"><a routerLink="/home">Home</a></li>
    <li *ngIf="userType !== 'adm'"><a [routerLink]="profileLink">Profile</a></li>
    <li *ngIf="userType === 'reg'"><a routerLink="/user/learning-buddy">Customize Buddy</a></li>
    <li><a routerLink="/project-info">Project Info</a></li>
    <li><a routerLink="/eula-info">Terms of Use</a></li>
    <li><a routerLink="/tech-support">Help/Tech Support</a></li>
    <li><a (click)="onLogOut()">Log Out</a></li>
  </ul>
</nav>
