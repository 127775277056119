import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { AssetLoadService } from '../services/asset-load.service';

@Injectable({ providedIn: 'root' })
export class JsonResolver implements Resolve<any> {

  constructor(private assetService: AssetLoadService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (typeof route.data.jsonUrl === 'string') {
      return this.assetService.loadJSON(route.data.jsonUrl);
    } else {
      return forkJoin(route.data.jsonUrl.map((url: string) => this.assetService.loadJSON(url)));
    }
  }
}
