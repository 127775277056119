import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// state
import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
// pipes
import { PhonePipe } from './pipes/phone.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NumberCommaPipe } from './pipes/number-comma.pipe';
// directives
import { DatasetDirective } from './directives/dataset.directive';
import { SwipeDirective } from './directives/swipe.directive';
import { TimerDirective } from './directives/timer.directive';
// components
import { LoadingAnimShellComponent } from './loading-anim-shell/loading-anim-shell.component';
import { SuperModalComponent } from './super-modal/super-modal.component';
import { NoteComponent } from './note/note.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { DotsComponent } from './dots/dots.component';
import { ButtonComponent } from './button/button.component';
import { LoaderAnimComponent } from './loader-anim/loader-anim.component';
import { SlideShowArrowComponent } from './slide-show-arrow/slide-show-arrow.component';
import { SliderComponent } from './slider/slider.component';
import { AudioButtonComponent } from './audio-button/audio-button.component';
import { SubPageContainerComponent } from './sub-page-container/sub-page-container.component';
import { SelectorComponent } from './selector/selector.component';
import { TwirlDownDirective } from './directives/twirl-down.directive';
import { NewsComponent } from './news/news.component';
import { RecordsResultsComponent } from './records-results/records-results.component';
import { RecordsFormComponent } from './records-form/records-form.component';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forFeature('sharedModule', reducers),
  ],
  declarations: [
    SafeHtmlPipe,
    PhonePipe,
    NumberCommaPipe,
    DatasetDirective,
    SwipeDirective,
    TimerDirective,
    LoadingAnimShellComponent,
    LoaderAnimComponent,
    SuperModalComponent,
    NoteComponent,
    SimpleModalComponent,
    DotsComponent,
    ButtonComponent,
    SlideShowArrowComponent,
    SliderComponent,
    AudioButtonComponent,
    SubPageContainerComponent,
    SelectorComponent,
    TwirlDownDirective,
    NewsComponent,
    RecordsResultsComponent,
    RecordsFormComponent,
    LoadingAnimShellComponent,
    LoaderAnimComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SafeHtmlPipe,
    PhonePipe,
    NumberCommaPipe,
    DatasetDirective,
    SwipeDirective,
    TimerDirective,
    LoadingAnimShellComponent,
    LoaderAnimComponent,
    SuperModalComponent,
    NoteComponent,
    SimpleModalComponent,
    DotsComponent,
    ButtonComponent,
    SlideShowArrowComponent,
    SliderComponent,
    AudioButtonComponent,
    SubPageContainerComponent,
    SelectorComponent,
    TwirlDownDirective,
    NewsComponent,
    RecordsResultsComponent,
    RecordsFormComponent,
    LoadingAnimShellComponent,
    LoaderAnimComponent
  ],
  providers: [NumberCommaPipe]
})
export class SharedModule { }
