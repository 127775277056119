import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { AuthGuard } from './authorization/auth.guard';
import { AppPreloadingStrategy } from './app.preloading.strategy';
import { HomeComponent } from './core/home/home.component';
import { BuddyResolver } from './core/resolvers/buddy.resolver';
import { ProjectInfoComponent } from './core/project-info/project-info.component';
import { TechSupportComponent } from './core/tech-support/tech-support.component';
import { EulaComponent } from './core/eula/eula.component';
import { JsonPaths } from './constants';
import { JsonResolver } from './core/resolvers/json.resolver';

const routes: Routes = [
  {
    path: 'login',
    data: {title: 'Login' },
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    resolve: {buddy: BuddyResolver}
  },
  {
    path: 'project-info',
    component: ProjectInfoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'eula-info',
    component: EulaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tech-support',
    component: TechSupportComponent,
    canActivate: [AuthGuard],
    data: { jsonUrl: JsonPaths.TECH_SUPPORT.valueOf() },
    resolve: { json: JsonResolver }
  },
  {
    path: 'user',
    data: { preload: true, delay: false },
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pat',
    data: { preload: true, delay: false },
    loadChildren: () => import('./learning-modules/pain-assessment/pain-assessment.module').then(m => m.PainAssessmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'comm',
    data: { preload: true, delay: true },
    loadChildren: () => import('./learning-modules/communication/communication.module').then(m => m.CommunicationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sickle-cell',
    data: { preload: true, delay: true },
    loadChildren: () => import('./learning-modules/sickle-cell/sickle-cell.module').then(m => m.SickleCellModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    data: { preload: false },
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    data: { preload: false },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parent-guardian',
    data: { title: 'Parent/Guardian - Home', preload: false },
    loadChildren: () => import('./parent-provider/parent-provider.module').then(m => m.ParentProviderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'provider',
    data: { title: 'Provider - Home', preload: false },
    loadChildren: () => import('./parent-provider/parent-provider.module').then(m => m.ParentProviderModule),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
