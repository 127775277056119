import { Component, ViewChildren, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { gsap } from 'gsap/all';

@Component({
  selector: 'kb-loader-anim',
  templateUrl: './loader-anim.component.html',
  styleUrls: ['./loader-anim.component.scss']
})
export class LoaderAnimComponent implements AfterViewInit {
  @ViewChildren('circles') private circles: any;
  @Input() href: string;

  private tl: gsap.core.Timeline;
  private tl2: gsap.core.Timeline;

  constructor() {
  }

  ngAfterViewInit() {
    this.circles = this.circles.toArray().map(el => el.nativeElement);
    this.circles.reverse();
    gsap.set(this.circles, { transformOrigin: 'center', fill: 'rgba(255, 51, 51, 0)' });
    gsap.set(this.circles[0], { scale: 1.3, fill: 'rgba(255, 51, 51, 1)' });
    this.buildTL();
  }

  play() {
    this.tl.play();
  }

  pause() {
    this.tl.pause();
  }

  private buildTL() {
    this.tl = gsap.timeline({ paused: true, yoyo: true, repeat: -1, ease: 'power1.inOut'})
      .fromTo([this.circles[1], this.circles[2], this.circles[3]], { scale: 1 }, { duration: 0.2, stagger: 0.1, scale: 1.3, fill: 'rgba(255, 51, 51, 1)', ease: 'power3.in'})
      .to([this.circles[0], this.circles[1], this.circles[2], this.circles[3]], { duration: 0.2, stagger: 0.1, scale: 1, fill: 'rgba(255, 51, 51, 0)', ease: 'power3.in' }, '-=.3')
      .to(this.circles[4], { duration: 0.2, fill: 'rgba(255, 51, 51, 1)', scale: 1.3 }, '-=.1');
  }
}
