/// <reference types="@types/preloadjs" />
/// <reference types="@types/soundjs" />
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, fromEvent, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'latest-createjs/lib/preloadjs/preloadjs';
import 'latest-createjs/lib/soundjs/soundjs';
import { CustomError, LoadItemData, AssetData, LoadItemEvent } from 'src/app/models';

/**
 * Service for loading JSON files and for loading images/audio via createjs.LoadQueue wrapped in an Observable.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetLoadService {

  private queue: createjs.LoadQueue;
  private jsonCache = {};
  private soundInstalled = false;

  /**
   * AssetLoadService constructor creates new createjs.LoadQueue with preferXHR set to false (no blob).
   */
  constructor(private http: HttpClient) {
    this.queue = new createjs.LoadQueue(false);
  }

  /**
   * Load JSON file. Can cache JSON data in the jsonCache object to eliminate future HTTP GET requests.
   * @param url path to json file
   * @param cache cache json data if true
   */
  loadJSON(url: string, cache: boolean = true): Observable<any> {
    if (this.jsonCache[url]) {
      return of(this.jsonCache[url]);
    } else {
      return this.http.get<any>(url)
        .pipe(
          tap(data => {
            if (cache) this.jsonCache[url] = data;
          }),
          catchError(this.handleError)
        );
    }
  }

  /**
   * Loads sounds or images via createjs.LoadQueue.
   * @param arr CreateJS manifest of sounds or images to load
   * @param installSoundPlugIn install plugin createjs.Sound if true
   */
  loadManifest(arr: Array<LoadItemData | string | any[]>, installSoundPlugIn: boolean = false): Observable<AssetData> {
    const returnObj = {};
    if (installSoundPlugIn && !this.soundInstalled) {
      this.queue.installPlugin(createjs.Sound);
      createjs.Sound.alternateExtensions = ['ogg'];
      this.soundInstalled = true;
    }
    this.queue.loadManifest(arr);
    return fromEvent(this.queue, 'complete')
      .pipe(
        map(data => {
          arr.forEach(val => {
            if (typeof val === 'string') {
              returnObj[val] = (data as LoadItemEvent).target.getResult(val);
            } else if ((val as LoadItemData).id || (val as LoadItemData).src) {
              returnObj[(val as LoadItemData).id || (val as LoadItemData).src] = (data as LoadItemEvent).target.getResult((val as LoadItemData).id || (val as LoadItemData).src);
            }
          });
          this.queue.removeAllEventListeners('complete');
          return returnObj;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Radomizes array.
   * @param dta Array to be randomized
   * @returns Randomized array
   */
  randomize(dta: any[]): any[] {
    return dta.map(obj => {
      obj.random = Math.random();
      return obj;
    }).sort((a, b) => a.random - b.random);
  }

  /**
   * Custom error hadling for this service.
   * @param err original http error response
   * @returns custom error
   */
  private handleError(err: HttpErrorResponse): Observable<never> {
    const error: CustomError = {
      status: err.status,
      message: 'There was a problem loading data, assets, and/or media.'
    };
    return throwError(error);
  }
}
