import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageService } from '../core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private okGo: boolean;
  private userType: string;
  private path: string;

  constructor(private router: Router, private storage: StorageService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.path = route.url[0].path;
    this.userType = this.storage.get('type');
    return this.path === 'login' || this.path === 'register' ?  this.allowLoginRegister() : this.allowContent();
  }

  /**
   * Determines if navigation to page is allowed by user type.
   * If token is not present, the user is redirected to the login page.
   */
  private allowContent(): boolean {
    this.okGo = this.storage.get('token') ? true : false;
    if (this.okGo && this.userType === 'reg' && (this.path.includes('adm') || this.path.includes('parent-guardian') || this.path.includes('provider'))) this.okGo = false;
    else if (this.okGo && this.userType === 'adm' && !this.path.includes('admin') && (this.path !== 'project-info' && this.path !== 'tech-support' && this.path !== 'eula-info')) this.okGo = false;
    else if (this.okGo && this.userType === 'par' && !this.path.includes('parent-guardian') && (this.path !== 'project-info' && this.path !== 'tech-support' && this.path !== 'eula-info')) this.okGo = false;
    else if (this.okGo && this.userType === 'med' && !this.path.includes('provider') && (this.path !== 'project-info' && this.path !== 'tech-support' && this.path !== 'eula-info')) this.okGo = false;
    //
    if (!this.okGo) this.router.navigateByUrl('/login');
    return this.okGo;
  }

  /**
   * Determines if login or registration pages are allowed to be navigated to.
   * If token is present, the user is redirected to /home, /admin, /parent-guardian, or /provider depending on user type.
   */
  private allowLoginRegister(): boolean {
    this.okGo = this.storage.get('token') ? false : true;
    if (!this.okGo) this.router.navigateByUrl(this.userType === 'reg' ? '/home' : (this.userType === 'adm' ? '/admin' : (this.userType === 'par' ? '/parent-guardian' : '/provider')) );
    return this.okGo;
  }
}
