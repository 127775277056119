import { Component, OnInit, ComponentFactoryResolver, Injector, ViewChild, ViewContainerRef, OnChanges, SimpleChange, SimpleChanges, Input, ComponentRef } from '@angular/core';
import { CustomItemData } from 'src/app/models';

/**
 * This component lazy loads {@link BuddySVGComponent} as a module and injects the component into it's template.
 * The BuddySVGComponent has a large file size and is only loaded for the reg user when it's first needed.
 * This component acts like a liason between a component that contains this shell and the BuddySVGComponent instance.
 * Data is passed from this components customItem input to the BuddySVGComponent instance's customItem input.
 */
@Component({
  selector: 'ppt-buddy-shell',
  templateUrl: './buddy-shell.component.html'
})
export class BuddyShellComponent implements OnInit, OnChanges {

  @ViewChild('buddyContainer', { read: ViewContainerRef, static: false }) buddyContainer: ViewContainerRef;
  @Input() customItem: CustomItemData;
  private buddyRef: ComponentRef<any>;

  constructor(
    private cfr: ComponentFactoryResolver,
    private injector: Injector
  ) { }

  /** Lazy loading is triggerd on initialization. */
  ngOnInit() {
    this.lazyLoadBuddySVG();
  }

  /** Passes customItem data to the BuddySVGComponent instance and calls the instance's ngOnChanges method. */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.buddyRef && changes.customItem) {
      this.buddyRef.instance.customItem = this.customItem;
      this.buddyRef.instance.ngOnChanges({
        customItem: new SimpleChange(changes.customItem.previousValue, this.customItem, changes.customItem.firstChange)
      });
    }
  }

  /** Lazy loads {@link BuddySVGComponent} as module and injects the component into the buddyContainer and buddyRef is defined. */
  private async lazyLoadBuddySVG() {
    const mod = await import('../buddy-svg/buddy-svg.component');
    const factory = this.cfr.resolveComponentFactory(mod.BuddySVGComponent);
    this.buddyRef = this.buddyContainer.createComponent(factory, null, this.injector);
  }

}
