import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of} from 'rxjs';
import { BuddyService } from 'src/app/buddy/buddy.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BuddyResolver implements Resolve<string> {

  constructor(private buddyService: BuddyService) { }

  resolve(): Observable<string> {
    return this.buddyService.name$.value ? of(this.buddyService.name$.value) : this.buddyService.name$.pipe(take(2));
  }
}
