import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import * as loadAnimActions from 'src/app/shared/loading-anim-shell/state/loading-anim.actions';
import { UserService } from 'src/app/core/services/user.service';
import { Subscription, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AudioFlyService } from '../services/audio-fly.service';

@Component({
  selector: 'ppt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('buddy') buddyEl: ElementRef;
  first = false;
  login = false;
  private subs: Subscription[];
  private username: string;
  private audio: createjs.AbstractSoundInstance;

  constructor(
    private store: Store<fromShared.State>,
    private userService: UserService,
    private route: ActivatedRoute,
    private audioFly: AudioFlyService
  ) { }

  ngOnInit() {
    this.subs = [
      this.audioFly.audio$.subscribe(sound => {
        this.audio = sound;
        if (this.first) this.onFirstAudio();
      }),
      combineLatest([this.userService.firstLogin$, this.userService.username$])
        .subscribe(arr => {
          this.username = arr[1];
          this.login = true;
          if (arr[0]) {
            this.first = true;
            this.onFirstLogin();
          } else {
            this.onLogin();
          }
        })
    ];
  }

  ngOnDestroy() {
    if (this.audio) this.audio.destroy();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private onFirstLogin() {
    this.audioFly.registerSound({
      id: `${this.route.snapshot.data.buddy.toLowerCase()}-intro`,
      src: `./assets/audio/buddy-intro/learnbud-intro-${this.route.snapshot.data.buddy.toLowerCase()}.mp3` },
      { delay: 600 }
    );
    this.store.dispatch(new loadAnimActions.Show());
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.Insert({ element: this.buddyEl.nativeElement, before: 'html' }));
    this.store.dispatch(new superModalActions.Title(`Welcome to Pinpoint, ${this.username}!`));
    this.store.dispatch(new superModalActions.HTML(`<h2>Meet your Learning Buddy, ${this.route.snapshot.data.buddy}.</h2>`));
    this.store.dispatch(new superModalActions.Buttons([
      {
        hide: false,
        text: `Play/Pause ${this.route.snapshot.data.buddy}'s Audio`,
        icon: 'audio',
        disabled: true
      },
      {
        text: 'Go To Body Scanner Reflection',
        icon: 'next',
        link: '/pat/reflection',
        func: () => this.audio ? this.audio.play({ volume: 0 }) : null
      }
    ]));
    this.store.dispatch(new superModalActions.Show());
  }

  private onFirstAudio() {
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.Buttons([
      {
        hide: false,
        text: `Play/Pause ${this.route.snapshot.data.buddy}'s Audio`,
        icon: 'audio',
        func: () => {
          if (this.audio.playState === createjs.Sound.PLAY_SUCCEEDED && this.audio.position > 200) {
            this.audio.paused = this.audio.paused ? false : true;
          } else {
            this.audio.play();
          }
        }
      },
      {
        text: 'Go To Body Scanner Reflection',
        icon: 'next',
        link: '/pat/reflection',
        func: () => this.audio.play({ volume: 0 })
      }
    ]));
  }

  private onLogin() {
    this.audioFly.registerSound({id: 'correct', src: './assets/audio/universal/correct.mp3'}, {volume: 0} );
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.Title(`Welcome Back, ${this.userService.username$.value}!`));
    this.store.dispatch(new superModalActions.Text('Would you like to report any pain for today or the last 7 days?'));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'No, Maybe Later',
      icon: 'close',
      class: 'small',
      func: () => this.audio.play()
    }, {
      text: 'Yes, Go To The Pinpoint Assesment Tool',
      icon: 'next',
      class: 'small',
      link: '/pat/draw',
      func: () => this.audio.play()
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

}
