import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[kbDataset]'
})
export class DatasetDirective implements OnInit {

  @Input() dataset: any;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    for (const key in this.dataset) {
      if (this.dataset.hasOwnProperty(key)) {
        this.el.nativeElement.dataset[key] = this.dataset[key];
      }
    }
  }

}
