import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../core/services/storage.service';

/**
 * TokenInterceptor injects a web token into the headers of all http requests except for requests loading assets or accessing data via 3rd party APIs.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.get('token');
    if (token && !req.url.includes('assets/') && !req.url.includes('api.wordnik') && !req.url.includes('dictionaryapi') ) {
      const headers = req.headers.set('Authorization', `Bearer ${token}` );
      return next.handle( req.clone({ headers }) );
    } else {
      return next.handle(req);
    }
  }
}
