import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from './user.service';
import { NumberCommaPipe } from 'src/app/shared/pipes/number-comma.pipe';

/**
 * Service that manages getting/updating and adding/subtracting the user's points.
 *
 * See {@link UserMenuComponent}, {@link EditBuddyComponent}, {@link ProfileComponent}, {@link PracticeSelfDisclosureComponent},
 * {@link SelfDisclosureGameComponent}, {@link VocabGameComponent}, {@link BodyScannerService}, {@link CircleSurveyComponent}, {@link DrawPainComponent}
 */
@Injectable({
  providedIn: 'root'
})
export class PointsService {

  /** Set and pushes out user's points */
  points$ = new BehaviorSubject<number>(0);
  /** Notifies subscribers when update call to backend fails */
  updateError$ = new Subject<Error>();
  /** Message defined when points are added */
  message: string;

  constructor(
    private userService: UserService,
    private numCommaPipe: NumberCommaPipe
  ) {}

  /**
   * Adds points via update method and defines and return messsage.
   * @return Example: 'Points Earned: 1,200'
   */
  add(num: number) {
    this.message = `Points Earned: ${this.numCommaPipe.transform(num)}`;
    this.update(this.points$.value + num);
    return this.message;
  }

  /** Substracts points via update method. */
  subtract(num: number) {
    this.update(this.points$.value - num);
  }

  /**
   * Gets the users's points from the database by subscribing to [UserService.getPoints()]{@link UserService#getPoints},
   * and updates the points$ BehaviorSubject on success.
   */
  get() {
    this.userService.getPoints().subscribe(
      num => this.points$.next(num)
    );
  }

  /** Updates the points in the database via subscribing to [UserService.updatePoints()]{@link UserService#updatePoints}, then updates points$ BehaviorSubject on success. */
  private update(num: number) {
    this.userService.updatePoints(num).subscribe(
      () => this.points$.next(num),
      err => this.updateError$.next(err)
    );
  }
}
