import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { LoginData, BuddyData, DateRangeData, NewsItemData, UserData, LinkedUserData, ClickStreamData } from 'src/app/models';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map, retry } from 'rxjs/operators';
import { StorageService } from 'src/app/core/services/storage.service';
import { DrawFinalReportData } from 'src/app/learning-modules/pain-assessment/draw-pain/models';
import { PainSurveyReportData } from 'src/app/learning-modules/pain-assessment/circle-survey/models';
import { DictionaryKeyData } from 'src/app/learning-modules/communication/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  username$ = new BehaviorSubject<string>(null);
  phone$ = new BehaviorSubject<string>(null);
  parents$ = new BehaviorSubject<LinkedUserData[]>(null);
  providers$ = new BehaviorSubject<LinkedUserData[]>(null);
  teens$ = new BehaviorSubject<LinkedUserData[]>(null);
  loggedIn$ = new BehaviorSubject<boolean>(false);
  bodySex$ = new BehaviorSubject<string>('M');
  firstLogin$ = new Subject<boolean>();
  userType$ = new BehaviorSubject<string>(null);

  private uid: string | number;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) {
    this.init();
  }

  /**
   * For refresh, executed in constructor, gets data from storage, and sets said data to important BehaviorSubjects.
   * Timeout so default body-sex doesn't override whats in storage.
   */
  private init() {
    const un = this.storage.get('username');
    if (un) {
      this.username$.next(un);
      const type = this.storage.get('type');
      const lgi = this.storage.get('loggedIn');
      const token = this.storage.get('token');
      const sex = this.storage.get('body-sex');
      this.uid = this.storage.get('uid');
      if (type) this.userType$.next(type);
      if (lgi || token) this.loggedIn$.next(true);
      if (sex) this.bodySex$.next(sex);
    }
    //
    setTimeout(() => {
      this.bodySex$.subscribe(sex => this.storage.save('body-sex', sex));
    }, 500);
  }

  /**
   * Sets UserData returned on login success to this service's BehaviorSubjects and saves data need for refresh to storage.
   * Then routes user to the approprate url based on user's type.
   * @param dta Data returned on login success
   */
  private onLogin(dta: UserData ) {
    this.username$.next(dta.username);
    this.loggedIn$.next(true);
    this.userType$.next(dta.type);
    this.uid = dta.uid;
    this.storage.save('username', dta.username, true)
      .save('uid', dta.uid, true)
      .save('loggedIn', true, true)
      .save('token', dta.token, true)
      .save('type', dta.type, true);
    //
    switch (dta.type) {
      case 'reg':
        this.router.navigateByUrl('/home');
        setTimeout(() => {
          this.firstLogin$.next(dta.firstlogin);
        }, 2500);
        break;

      case 'par':
        this.router.navigateByUrl('/parent-guardian');
        break;

      case 'med':
        this.router.navigateByUrl('/provider');
        break;

      case 'adm':
        this.router.navigateByUrl('/admin/add-news-item');
        break;

      default:
        break;
    }
  }

  login(creds: LoginData): Observable<UserData> {
    return this.http.post<UserData>('api/login', creds).pipe(
      tap(dta => this.onLogin(dta)),
      catchError(err => this.throwCustomError(err, 'We didn\'t recognize the username or password you entered, or there was a problem connecting to the server. Please try again.'))
    );
  }

  accountRecovery(email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post('api/recovery', `"${email}"`, { headers }).pipe(
      catchError(err => this.throwCustomError(err, 'Email not recognized, or there was a problem connecting to the server.'))
    );
  }

  logout() {
    if (this.loggedIn$.value) this.http.post('api/logout', null).subscribe();
    this.storage.clear();
    this.username$.next(null);
    this.loggedIn$.next(false);
    this.userType$.next(null);
    this.phone$.next(null);
    this.parents$.next(null);
    this.providers$.next(null);
    this.teens$.next(null);
    this.router.navigateByUrl('/login');
  }

  updatePassword(password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put('api/user/password', `"${password}"`, { headers }).pipe(
      catchError(err => this.throwCustomError(err, 'Unable to update password.'))
    );
  }

  /** This also updates the username in the DB, as email & username are the same. */
  updateEmail(email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put('api/user/email', `"${email}"`, { headers }).pipe(
      tap(() => this.username$.next(email)),
      catchError(err => this.throwCustomError(err, 'Unable to update email.'))
    );
  }

  getPhone(): Observable<string> {
    return this.http.get<string>('api/user/phone').pipe(
      retry(2),
      tap(response => this.phone$.next(response)),
      catchError(err => throwError(err))
    );
  }

  updatePhone(phone: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put('api/user/phone', `"${phone}"`, { headers }).pipe(
      tap(() => this.phone$.next(phone)),
      catchError(err => this.throwCustomError(err, 'Unable to update phone number.'))
    );
  }

  /**
   * Executed when getLinkedUsers succeeds.
   * The parents$, providers$, or teens$ BehaviorSubjects are updated based on the 'type' param.
   *
   * LinkedUserData objects are mapped to have properties in a specific order in order for camparison by stringification to work correctly.
   * See {@link EditParentsComponent}, {@link EditProvidersComponent}
   * @param type UserType of linked users
   * @param arr Array of LinkedUserData that is passed BehaviorSubject
   */
  private onLinkedUsers(type: 'reg' | 'par' | 'med', arr: LinkedUserData[]) {
    const newArr = arr.map(obj => {
      return { email: obj.email, id: obj.id, name: obj.name, phone: obj.phone, primary: obj.primary, type: obj.type, uid: obj.uid };
    });
    switch (type) {
      case 'par':
        this.parents$.next(newArr);
        break;

      case 'med':
        this.providers$.next(newArr);
        break;

      case 'reg':
        this.teens$.next(newArr);
        break;

      default:
        break;
    }
  }

  /**
   * Executed when a linked user is successfully deleted.
   * This function filters out the deleted user from specified BehavoirSubject data based on the params.
   * This is in order to update the data without having to recall getLinkedUsers.
   * @param id ID of linked user deleted
   * @param type UserType of deleted linked user
   */
  private onLinkedUserDeleted(id: string | number, type: 'reg' | 'par' | 'med') {
    switch (type) {
      case 'par':
        this.parents$.next(this.parents$.value.filter(obj => obj.id === id ? false : true));
        break;

      case 'med':
        this.providers$.next(this.providers$.value.filter(obj => obj.id === id ? false : true));
        break;

      case 'reg':
        this.teens$.next(this.teens$.value.filter(obj => obj.id === id ? false : true));
        break;

      default:
        break;
    }
  }

  /**
   * Returns definition of user type for meaningful error messaging.
   */
  private getUserType(type: 'reg' | 'par' | 'med'): string {
    if (type === 'par') return 'parent/guardian';
    if (type === 'med') return 'medical provider';
    if (type === 'reg') return 'teen user';
  }

  getLinkedUsers(type: 'reg' | 'par' | 'med'): Observable<LinkedUserData[]> {
    return this.http.get<LinkedUserData[]>(`api/link/${type}`).pipe(
      retry(2),
      // map to put primary at index 0
      map(arr => [arr.find(val => val.primary), ...arr.filter(val => !val.primary)].filter(val => val)),
      tap(response  => this.onLinkedUsers(type, response)),
      catchError(err => throwError(err))
    );
  }

  updateLinkedUsers(type: 'reg' | 'par' | 'med', arr: LinkedUserData[]): Observable<any> {
    arr.forEach(obj => obj.uid = this.uid);
    return this.http.put(`api/link/${type}`, arr).pipe(
      tap(() => this.getLinkedUsers(type).subscribe()),
      catchError(err => this.throwCustomError(err, `Unable to update ${this.getUserType(type)}s.`))
    );
  }

  deleteLinkedUser(id: string | number, type: 'reg' | 'par' | 'med'): Observable<any> {
    return this.http.delete(`api/link/${id}`).pipe(
      tap(() => this.onLinkedUserDeleted(id, type)),
      catchError(err => this.throwCustomError(err, `Unable to delete ${this.getUserType(type)}.`))
    );
  }

  getPoints(): Observable<number> {
    return this.http.get<number>('api/points').pipe(
      retry(2),
      catchError(err => throwError(err))
    );
  }

  updatePoints(num: number): Observable<any> {
    return this.http.put('api/points', num).pipe(
      catchError(err => this.throwCustomError(err, 'Unable to update points.'))
    );
  }

  getBuddy(): Observable<BuddyData> {
    return this.http.get<BuddyData>('api/buddy').pipe(
      retry(2),
      tap(dta => this.bodySex$.next(dta.name === 'Jalen' ? 'M' : 'F')),
      catchError(err => this.throwCustomError(err, 'Unable to load Buddy data.'))
    );
  }

  updateBuddy(dta: BuddyData): Observable<any> {
    return this.http.put('api/buddy', dta).pipe(
      tap(() => this.bodySex$.next(dta.name === 'Jalen' ? 'M' : 'F')),
      catchError(err => this.throwCustomError(err, 'Unable to update Buddy.'))
    );
  }

  getWordCategories(): Observable<string[]> {
    return this.http.get<string[]>('api/wordcats').pipe(
      retry(2),
      catchError(err => this.throwCustomError(err, 'Unable to get user\'s word category data.'))
    );
  }

  updateWordCategories(arr: string[]): Observable<any> {
    return this.http.put('api/wordcats', arr).pipe(
      catchError(err => throwError(err))
    );
  }

  getDrawPainData(dateRange: DateRangeData): Observable<DrawFinalReportData[]> {
    let params = new HttpParams().set('start', dateRange.start.toString()).set('end', dateRange.end.toString());
    if (dateRange.uid) params = params.set('uid', dateRange.uid.toString());
    return this.http.get<DrawFinalReportData[]>('api/draw', {params}).pipe(
      retry(2),
      catchError(err => this.throwCustomError(err, 'Unable to retrieve Draw My Pain Data.'))
    );
  }

  postDrawPainData(dta: DrawFinalReportData): Observable<any> {
    return this.http.post('api/draw', dta).pipe(
      catchError(err => this.throwCustomError(err, 'Unable to save Draw My Pain data.'))
    );
  }

  postCrisisPain(): Observable<any> {
    return this.http.post('api/crisis', new Date().getTime() ).pipe(
      retry(2),
      catchError(err => throwError(err))
    );
  }

  getSurveyData(dateRange: DateRangeData): Observable<PainSurveyReportData[]> {
    let params = new HttpParams().set('start', dateRange.start.toString()).set('end', dateRange.end.toString());
    if (dateRange.uid) params = params.set('uid', dateRange.uid.toString());
    return this.http.get<PainSurveyReportData[]>('api/survey', {params}).pipe(
      retry(2),
      catchError(err => this.throwCustomError(err, 'Unable to retrieve Pain Survey Data.'))
    );
  }

  postSurveyData(dta: PainSurveyReportData): Observable<any> {
    return this.http.post('api/survey', dta).pipe(
      catchError(err => this.throwCustomError(err, 'Unable to save Pain Survey.'))
    );
  }

  getNewsData(): Observable<NewsItemData[]> {
    return this.http.get<NewsItemData[]>('api/news').pipe(
      retry(2),
      map(dta => dta.reverse()),
      catchError(err => this.throwCustomError(err, 'Unable to load news items.'))
    );
  }

  postNewsData(dta: NewsItemData): Observable<any> {
    return this.http.post('api/news', dta).pipe(
      catchError(err => this.throwCustomError(err, 'Unable to post news item.'))
    );
  }

  deleteNewsData(id: number): Observable<any> {
    return this.http.delete(`api/news/${id}`).pipe(
      catchError(err => this.throwCustomError(err, 'Unable to delete news item.'))
    );
  }

  getDictionaryKeys(): Observable<DictionaryKeyData> {
    return this.http.get<DictionaryKeyData>('api/dictkeys').pipe(
      retry(2),
      catchError(err => this.throwCustomError(err, 'Unable to retreive dictionary API keys.'))
    );
  }

  updateDictionaryKeys(keys: DictionaryKeyData): Observable<any> {
    return this.http.put('api/dictkeys', keys).pipe(
      retry(2),
      catchError(err => this.throwCustomError(err, 'Unable to update dictionary API keys.'))
    );
  }

  clickStream(dta: ClickStreamData): Observable<any> {
    return this.http.post('api/user/activity', dta).pipe(
      retry(2),
      catchError(err => throwError(err))
    );
  }

  /**
   * Throws custom error with messaging directed towards the user and specific to the failed http call.
   * If the error's status is 401 (unauthorized), the user will be logged out.
   * @param err Original error returned from failed http call
   * @param msg Message to display to the user
   */
  private throwCustomError(err: HttpErrorResponse, msg: string): Observable<never> {
    const error = new Error(`
      <h2 class="text-center error">ERROR! ${err.status}</h2>
      <p class="error bold text-center">Something went wrong!</p>
      <p class="text-center">${msg}</p>
      ${err.status.toString() === '404' ? `<p class="text-center">Make sure your device is connected to the internet.</p>` : ''}
      `
    );
    // 401: unauthorized
    if (err.status.toString() === '401') {
      setTimeout(() => {
        this.logout();
        document.location.reload();
      }, 2000);
    }
    return throwError(error);
  }

}
