import { Component, OnInit, ViewChildren, QueryList, ElementRef, NgZone } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { MenuData } from '../../models';
import { gsap } from 'gsap';
import { UserService } from '../services/user.service';


@Component({
  selector: 'ppt-circle-btns',
  templateUrl: './circle-menu-btns.component.html',
  styleUrls: ['./circle-menu-btns.component.scss']
})
export class CircleMenuBtnsComponent implements OnInit {

  @ViewChildren('btns') btns: QueryList<ElementRef>;

  menuItems: MenuData[];
  btnsArr: HTMLElement[];
  showHide = 'hide';

  private subbed = false;
  private rot: number;
  private angle: number;
  private animHandle: number;

  constructor(private service: MenuService, private userService: UserService, private zone: NgZone) {}

  ngOnInit() {
    this.service.curMenuData$.subscribe(dta => this.onMenuData(dta));
    this.userService.loggedIn$.subscribe(b => this.showHide = b ? 'show' : 'hide');
  }

  private onMenuData(dta: MenuData) {
    if (dta && dta.menu) {
      const arr = dta.menu.map(item => Object.assign({}, { title: item.title, path: item.path, locked: item.locked }));
      if (this.btnsArr) {
        this.destroy(arr);
      } else if (!this.btns || this.btns.length === 0) {
        // first load: subToChanges()
        this.menuItems = arr;
        if (!this.subbed) this.subToChanges();
      } else {
        this.menuItems = arr;
      }
    } else if (dta && !dta.menu || !dta) {
      if (this.btnsArr) {
        this.destroy();
      }
    }
  }

  private onBtnChanges() {
    this.rot = 270;
    this.angle = (360 / this.btns.length);
    this.btns.forEach(elRef => this.setTransform(elRef.nativeElement));
    //
    if (this.btns.length > 0) {
      this.btnsArr = this.btns.toArray().map(elRef => elRef.nativeElement);
      gsap.fromTo(this.btnsArr,
        { scale: 0.6, transformOrigin: 'center center' },
        { autoAlpha: 1, scale: 1, duration: 0.5, stagger: 0.1, ease: 'back.out', onComplete: () => this.animate() }
      );
    }
  }

  private destroy(newItems?: MenuData[]) {
    window.cancelAnimationFrame(this.animHandle);
    gsap.to(this.btnsArr, {
      autoAlpha: 0,
      scale: 0.6,
      duration: 0.5,
      ease: 'back.out',
      stagger: 0.1,
      onComplete: () => {
        this.btnsArr = undefined;
        setTimeout(() => {
          this.zone.run(() => this.menuItems = newItems);
        }, 200);
      }
    });
  }

  private animate() {
    if (this.btnsArr) {
      this.btnsArr.forEach(btn => this.setTransform(btn));
      this.rot += 0.07;
      this.animHandle = window.requestAnimationFrame(() => this.animate());
    }
  }

  private setTransform(el: HTMLElement) {
    /* console.log(el.text); */
    gsap.set(el, { transform: `rotate(${this.rot}deg) translate(123px) rotate(${this.rot * -1}deg)`, force3D: true });
    this.rot += this.angle;
  }

  private subToChanges() {
    setTimeout(() => {
      this.btns.changes.subscribe(() => this.onBtnChanges());
      this.btns.notifyOnChanges();
      this.subbed = true;
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  }

}
