import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../state';
import * as fromLoadAnim from '../loading-anim-shell/state';
import * as fromSuperModal from '../super-modal/state';
import { reducer as LoadingAnimReducer } from '../loading-anim-shell/state/loading-anim.reducer';
import { reducer as SuperModalReducer } from '../super-modal/state/super-modal.reducer';

/* add states here */
export interface SharedModuleState {
    loadAnim: fromLoadAnim.State;
    superModal: fromSuperModal.State;
}

export interface State extends fromRoot.State {
    sharedModule: SharedModuleState;
}

/* add reducers here */
export const reducers = {
    loadAnim: LoadingAnimReducer,
    superModal: SuperModalReducer
};

export const selectSharedModuleState = createFeatureSelector<SharedModuleState>('sharedModule');

/* LoadingAnim */
export const selectLoadAnimState = createSelector(
    selectSharedModuleState, (state: SharedModuleState) => state.loadAnim
);

export const getLoadAnimShow = createSelector(
    selectLoadAnimState,
    fromLoadAnim.getShow
);

/* SuperModal */
export const selectSuperModalState = createSelector(
    selectSharedModuleState, (state: SharedModuleState) => state.superModal
);

export const getSuperModalShow = createSelector(
    selectSuperModalState,
    fromSuperModal.getShow
);

export const getSuperModalTitle = createSelector(
    selectSuperModalState,
    fromSuperModal.getTitle
);

export const getSuperModalText = createSelector(
    selectSuperModalState,
    fromSuperModal.getText
);

export const getSuperModalHTML = createSelector(
    selectSuperModalState,
    fromSuperModal.getHTML
);

export const getSuperModalButtons = createSelector(
    selectSuperModalState,
    fromSuperModal.getButtons
);

export const getSuperModalInsert = createSelector(
    selectSuperModalState,
    fromSuperModal.getInserted
);

export const getSuperModalImage = createSelector(
    selectSuperModalState,
    fromSuperModal.getImage
);

export const getSuperModalFirstLoad = createSelector(
    selectSuperModalState,
    fromSuperModal.getFirstLoad
);
