import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { slideUpAnimation } from 'src/app/animations/slide-up.animation';
import { MenuService } from '../services/menu.service';
import { AdminMenuData, ParentMenuData, ProviderMenuData } from 'src/app/constants';

@Component({
  selector: 'ppt-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  animations: [slideUpAnimation]
})
export class TopMenuComponent implements OnInit {

  @Input() backPath: string;

  menuState = 'hide'; // show || hide
  topNavState = 'hide'; // show || hide
  showUserMenu = false;
  showBackBtn = false;
  showUserBtn = true;
  upIndex = false;
  userRegister = false;

  constructor(
    private router: Router,
    private service: MenuService
    ) { }

  ngOnInit() {
    this.service.showCircleMenu$.subscribe(b => this.setUpIndex(!b));
    this.router.events.subscribe( e => {
      if (e instanceof NavigationEnd) {
        this.setVisability(e);
        this.onUserBtn(false);
      }
    });
  }

  onUserBtn(b: boolean) {
    this.showUserMenu = b;
    this.service.showUserMenu$.next(this.showUserMenu);
  }

  private setVisability(e: NavigationEnd) {
    this.userRegister = e.urlAfterRedirects.includes('user') || e.urlAfterRedirects.includes('register');
    //
    if (this.userRegister) {
      this.showUserBtn = !e.urlAfterRedirects.includes('register');
      this.topNavState = 'hide';
    } else {
      this.showUserBtn = true;
      this.topNavState = 'show';
    }
    //
    if (e.urlAfterRedirects.includes('/login')) {
      this.menuState = 'hide';
    } else {
      this.menuState = 'show';
    }
    //
    if (e.urlAfterRedirects === '/home'
        || e.urlAfterRedirects === AdminMenuData.subpages[0].path
        || e.urlAfterRedirects === ParentMenuData.path
        || e.urlAfterRedirects === ProviderMenuData.path
      ) {
      this.showBackBtn = false;
    } else {
      this.showBackBtn = true;
    }
  }

  private setUpIndex(b: boolean) {
    if (b) {
      setTimeout(() => {
        this.upIndex = true;
      }, 450);
    } else {
      this.upIndex = false;
    }
  }

}
