import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim-shell/state/loading-anim.actions';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import { MenuService } from './core/services/menu.service';
import { StorageService } from './core/services/storage.service';
import { UserService } from './core/services/user.service';
import { concat } from 'rxjs';

@Component({
  selector: 'ppt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  private title = 'Pinpoint';
  private pageTitle: string;
  private prevPageTitle: string;
  private firstLoad = true;
  private loggedIn: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private menuService: MenuService,
    private store: Store<fromShared.State>,
    private storage: StorageService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.loggedIn$.subscribe(b => this.loggedIn = b);
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        /* console.log(this.route.firstChild.firstChild, e); */
        if (this.route.firstChild.firstChild
          && !e.urlAfterRedirects.includes('/user/')
          && !e.urlAfterRedirects.includes('/sickle-cell/')
          && !e.urlAfterRedirects.includes('/records/form')
          && !e.urlAfterRedirects.includes('/parent-guardian/')
          && !e.urlAfterRedirects.includes('/provider/')
          && e.urlAfterRedirects !== '/comm/self-disclosure-stories') {
          this.store.dispatch(new loadAnimActions.Show());
        } else {
          this.store.dispatch(new loadAnimActions.Hide());
        }
        if (this.firstLoad) {
          this.store.dispatch(new superModalActions.FirstLoad(true));
          this.firstLoad = false;
        }
        this.timestamp();
        this.setTitle();
      }
    }
    );
  }

  private timestamp() {
    const last = this.storage.get('lastnav');
    this.storage.save('lastnav', Date.now());
    if (last && ((Date.now() - last) / 3600000) >= 4) {
      document.location.reload();
    }
  }

  private setTitle() {
    this.pageTitle = this.menuService.getCurrentPageTitle() || this.route.firstChild.snapshot.data.title;
    if (this.pageTitle) {
      this.titleService.setTitle(`${this.title}: ${this.pageTitle}`);
      this.reportClickStream();
    } else {
      setTimeout(() => {
        this.setTitle();
      }, 500);
    }
  }

  private reportClickStream() {
    if (this.loggedIn) {
      const arr = [];
      if (this.prevPageTitle) arr.push(this.userService.clickStream({ enter: false, page: this.prevPageTitle }));
      arr.push(this.userService.clickStream({ enter: true, page: this.pageTitle }));
      concat(...arr).subscribe();
      this.prevPageTitle = this.pageTitle;
    }
  }
}
