import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';

import { UserService } from '../services/user.service';
import { PointsService } from '../services/points.service';
import { MenuService } from '../services/menu.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'ppt-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('userMenuState', [
      state('hide', style({
        opacity: 0,
        display: 'hidden'
      })),
      state('show', style({
        opacity: 1,
        display: 'visible'
      })),
      transition('hide => show', animate('50ms linear')),
      transition('show => hide', animate('150ms 50ms linear')),
    ])
  ]
})
export class UserMenuComponent implements OnInit {

  state = 'hide';
  username = 'username';
  userType = 'reg';
  profileLink = '/user/profile';
  points = 0;

  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private pointsService: PointsService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.userService.username$.subscribe(name => this.username = name);
    combineLatest([this.userService.userType$, this.userService.loggedIn$])
      .subscribe( arr => {
        // arr[0] = userType, arr[1] = loggedIn
        this.onUserType(arr[0]);
        if (arr[1] && arr[0] === 'reg') this.pointsService.get();
      });
    this.menuService.showUserMenu$.subscribe(boo => this.state = boo ? 'show' : 'hide');
    this.pointsService.points$.subscribe(pts => this.points = pts);
  }

  onLogOut() {
    this.userService.logout();
  }

  animEnd(e: AnimationEvent) {
    this.el.nativeElement.style.pointerEvents = e.toState === 'hide' ? 'none' : 'all';
  }

  private onUserType(type: string) {
    /* console.log(type); */
    this.userType = type;
    this.profileLink = this.userType === 'reg' ? '/user/profile' : (this.userType === 'adm' ? null : (this.userType === 'par' ? '/parent-guardian/profile' : '/provider/profile'));
  }

}
