import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ppt-tech-support',
  template: `
  <div class="content-wrap">
    <p class="contact-text">If you have tech support or connectivity issues, please email <a href="mailto:info@pinpointscd.com">info@pinpointscd.com</a>.</p>
  </div>`,
  styleUrls: ['./tech-support.component.scss']
})
export class TechSupportComponent implements OnInit {

  url: SafeResourceUrl;
  private dangerUrl: string;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dangerUrl = this.route.snapshot.data.json.url;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerUrl);
  }

}
