import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberComma'
})
export class NumberCommaPipe implements PipeTransform {

  transform(num: number | string): string {
    if (num === undefined) return '';
    const arr: string[] = [];
    num.toString().split('').reverse().forEach((val, i) =>  {
      if (i % 3 === 0 && i !== 0) arr.push(',');
      arr.push(val);
    });
    return arr.reverse().join('');
  }

}
