import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kb-button',
  template: `
    <button [id]="id" [type]="type" class="kb-btn {{ class }}" [attr.data-type]="alt ? 'alt' : ''"
      [disabled]="disabled"
      [routerLink]="link"
      [queryParams]="queryParams"
      [queryParamsHandling]="queryParamsHandling">
      <span class="icon-span">
        <div class="icon-holder">
          <svg [ngClass]="icon">
            <use [attr.xlink:href]="'./assets/svg_store/store.svg#' + icon"></use>
          </svg>
        </div>
      </span>
      <span class="text-span">{{ text }}</span>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {

  @Input() text: string;
  @Input() id = btoa(Math.random().toString().split('.')[1]).substr(0, 6);
  @Input() class: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() alt: boolean;
  @Input() link: string;
  @Input() type = 'button';
  @Input() queryParams: { [k: string]: any };
  @Input() queryParamsHandling = 'preserve';

  constructor() { }

}
