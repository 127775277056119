import { Component } from '@angular/core';

@Component({
  selector: 'ppt-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent {

  constructor() { }

}
