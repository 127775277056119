import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, timer, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserService } from './core/services/user.service';

/**
 * This is the PreloadingStrategy for the app at the route level.
 * It uses the boolean properties preload and delay from the route's data to determine if modules are lazy loaded or preloaded with an optional delay of 200 milliseconds.
 *
 * See [AppRoutingModule's source]{@link AppRoutingModule#source}
 */
@Injectable({
  providedIn: 'root'
})
export class AppPreloadingStrategy implements PreloadingStrategy {

  /**
   * @param userService Required in the preload method.
   */
  constructor(private userService: UserService) {}

  /** In addition to having route.data.preload equal true, the user must be logged in and their userType must equal 'reg' in order to preload modules. */
  // tslint:disable-next-line: ban-types
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload && this.userService.loggedIn$.value && this.userService.userType$.value === 'reg'
        ? this.loadRoute(load, route.data.delay)
        : of(null);
  }

  // tslint:disable-next-line: ban-types
  private loadRoute(load: Function, delay: boolean): Observable<any> {
    return delay ? timer(200).pipe(flatMap( () => load())) : load();
  }
}
