import { MenuData, BuddyData } from './models';

export enum JsonPaths {
  MENU = './assets/json/menus.json',
  VOCAB_GAME = './assets/json/vocab-game.json',
  BODY_SCAN = './assets/json/body-scanner.json',
  DRAW_PAIN = './assets/json/draw-pain.json',
  WORD_BANK = './assets/json/word-bank.json',
  UNSCRAMBLE = './assets/json/unscramble.json',
  ABOUT_SELF_DISCLOSURE = './assets/json/about-self-disclosure.json',
  SELF_DISCLOSURE_GAME = './assets/json/self-disclosure-game.json',
  PRACTICE_SELF_DISCLOSURE = './assets/json/practice-self-disclosure.json',
  SELF_DISCLOSURE_STORIES = './assets/json/self-disclosure-stories.json',
  PAIN_SURVEY = './assets/json/pat.json',
  TECH_SUPPORT = './assets/json/tech-support.json'
}

export enum Colors {
  BG = 'rgb(48,48,48)',
  WHITE = '#FFF',
  BLACK = '#000',
  DARK_GREY = 'rgb(48, 48, 48)',
  LIGHTER_GREY = 'rgb(228, 228, 228)',
  LIGHT_GREY = 'rgb(128, 128, 128)',
  RED = 'rgb(255, 51, 51)',
  GREEN = '#78e50b',
  BLUE = '#0ddbff'
}

export const UserMenuData: MenuData = {
  title: 'User',
  path: '/user',
  back: '/home',
  subpages: [
    {
      title: 'Profile',
      path: '/user/profile',
      locked: false,
      back: '/home'
    },
    {
      title: 'Customize Leaning Buddy',
      path: '/user/learning-buddy',
      locked: false,
      back: '/user/profile'
    },
    {
      title: 'Edit Parents/Guardians',
      path: '/user/parents-guardians',
      locked: false,
      back: '/user/profile'
    },
    {
      title: 'Edit Medical Providers',
      path: '/user/medical-providers',
      locked: false,
      back: '/user/profile'
    }
  ]
};

export const ProjectInfoMenuData: MenuData = {
  title: 'Project Info',
  path: '/project-info',
  back: '/home'
};

export const EulaInfoMenuData: MenuData = {
  title: 'Terms of Use',
  path: '/eula-info',
  back: '/home'
};

export const TechSuppotMenuData: MenuData = {
  title: 'Help/Tech Support',
  path: '/tech-support',
  back: '/home'
};

export const ParentUserMenuData: MenuData = {
  title: 'Profile',
  path: '/parent-guardian/profile',
  back: '/parent-guardian'
};

export const ProviderUserMenuData: MenuData = {
  title: 'Profile',
  path: '/provider/profile',
  back: '/provider'
};

export const RegistrationMenuData: MenuData = {
  title: 'Registration',
  path: '/register',
  back: '/login',
  subpages: [
    {
      title: 'Registration - Username & Password',
      path: '/register/creds',
      locked: true,
      back: '/login'
    },
    {
      title: 'Registration - Security Question',
      path: '/register/security',
      locked: true,
      back: '/register/creds'
    },
    {
      title: 'Registration - Add Parent/Gaurdian',
      path: '/register/add-parent-guardian',
      locked: true,
      back: '/register/security'
    },
    {
      title: 'Registration - Add Medical Providers',
      path: '/register/add-providers',
      locked: true,
      back: '/register/add-parent-guardian'
    },
    {
      title: 'Registration - Choose a Learning Buddy',
      path: '/register/buddy',
      locked: true,
      back: '/register/add-physician'
    }
  ]
};

export const AdminMenuData: MenuData = {
  title: 'Admin',
  path: '/admin',
  back: null,
  subpages: [
    {
      title: 'Admin - Add News Item',
      path: '/admin/add-news-item',
      back: null
    },
    {
      title: 'Admin - View News Item',
      path: '/admin/in-the-news',
      back: '/admin/add-news-item'
    },
    {
      title: 'Admin - Edit Dictionary Keys',
      path: '/admin/edit-keys',
      back: '/admin/in-the-news'
    }
  ]
};

export const ParentMenuData: MenuData = {
  id: 'parent-guardian',
  title: 'Parent/Guardian',
  path: '/parent-guardian',
  menu: [
    {
      title: 'Get Records',
      path: '/parent-guardian/records',
      subpages: [
        {
          title: 'Get Records - Select Date Range',
          path: '/parent-guardian/records/form',
          locked: true
        },
        {
          title: 'Get Records - Results',
          path: '/parent-guardian/records/results',
          locked: true
        }
      ]
    },
    {
      title: 'In The News',
      path: '/parent-guardian/in-the-news'
    },
    {
      title: 'FAQ',
      path: '/parent-guardian/faq'
    }
  ]
};

export const ProviderMenuData: MenuData = {
  id: 'provider',
  title: 'Provider',
  path: '/provider',
  menu: [
    {
      title: 'Get Records',
      path: '/provider/records',
      subpages: [
        {
          title: 'Get Records - Select Date Range',
          path: '/provider/records/form',
          locked: true
        },
        {
          title: 'Get Records - Results',
          path: '/provider/records/results',
          locked: true
        }
      ]
    },
    {
      title: 'In The News',
      path: '/provider/in-the-news'
    }
  ]
};

export const BuddyColors = {
  SKIN: ['#42302A', '#572B18', '#82472F', '#9B582B', '#B3733D', '#D6A07B', '#F0B98F'],
  HAIR: ['#000', '#2D1715', '#653310', '#B19025', '#EAC85C', '#C3432C'],
  EYE: ['#4B2B20', '#8B4513', '#808080', '#08547D', '#147114'],
  SHIRT: ['#808080']
};

export const BuddyStyles = {
  HAIR: ['bald', 'low-cut', 'natural', 'long natural', 'straight', 'short cornrows', 'long cornrows', 'short locks', 'medium locks', 'long locks'],
  HATS: ['baseball', 'beanie', 'cowboy', 'none'],
  EYEWEAR: ['eyeglasses', 'sunglasses', 'none'],
  JACKETS: ['jean', 'motorcycle', 'none'],
  EARRINGS: ['diamond-stud', 'hoop', 'none'],
  NECKWEAR: ['gold necklace', 'beaded necklace', 'beaded choker', 'scarf', 'none']
};

export const PointsEarn = {
  BODYSCANNER: 750,
  SURVEY: 1500,
  DRAWPAIN: 1500,
  SDPRACTICE: 500
};

export const BuddyPrices = {
  HAIRCOLOR: 5000,
  EYECOLOR: 5000,
  SHIRTCOLOR: 5000,
  HAIRSTYLE: 5000,
  HATS: 7500,
  EYEWEAR: 7500,
  JACKETS: 7500,
  EARRINGS: 7500,
  NECKWEAR: 7500
};

export const DefaultBuddyData: BuddyData = {
  name: null,
  skinColor: {
    active: null,
    owned: BuddyColors.SKIN
  },
  eyeColor: {
    active: null,
    owned: []
  },
  hairColor: {
    active: null,
    owned: []
  },
  hairStyle: {
    active: null,
    owned: []
  },
  shirtColor: {
    active: BuddyColors.SHIRT[0],
    owned: BuddyColors.SHIRT
  },
  hats: {
    active: 'none',
    owned: ['none']
  },
  jackets: {
    active: 'none',
    owned: ['none']
  },
  eyeWear: {
    active: 'none',
    owned: ['none']
  },
  neckWear: {
    active: 'none',
    owned: ['none']
  },
  earrings: {
    active: 'none',
    owned: ['none']
  }
};

