<div class="top-menu-wrap bg" [ngClass]="{'is-user-register': userRegister}" [@slideUpAnimation]="menuState"></div>

<div class="top-menu-wrap" [@slideUpAnimation]="menuState">

  <button class="top-btn back" title="back" [disabled]="!showBackBtn" routerLink="{{ backPath }}" queryParamsHandling="preserve">
    <span>
    <svg>
      <use [attr.xlink:href]="'./assets/svg_store/store.svg#back-arrow'"></use>
    </svg>
    </span>
  </button>

  <button class="top-btn user" title="menu settings logout" [ngClass]="{'on': showUserMenu}" [disabled]="!showUserBtn" (click)="onUserBtn(showUserMenu ? false : true)">
    <span>
      <svg>
        <use [attr.xlink:href]="'./assets/svg_store/store.svg#user'"></use>
      </svg>
    </span>
  </button>

</div>

<div class="top-menu-wrap nav-wrap" [@slideUpAnimation]="topNavState" [style.zIndex]="upIndex ? 1000 : null">
  <ppt-top-nav-btns></ppt-top-nav-btns>
</div>
