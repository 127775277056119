import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { LoginData } from 'src/app/models';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ppt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('formEl', { static: true }) formEl: ElementRef;
  form: FormGroup;
  emailData = {
    email: undefined
  };
  creds: LoginData = {
    username: undefined,
    password: undefined
  };

  constructor(private userService: UserService, private store: Store<fromShared.State>) { }

  ngOnInit() {
    this.userService.logout();
  }

  onSubmit() {
    this.userService.login(this.creds).subscribe(
      () => console.log('loggin success'),
      err => this.onLoginError(err)
    );
  }

  onClose() {
    this.store.dispatch(new superModalActions.Hide());
  }

  private onLoginError(err: Error) {
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.HTML(err.message));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'OK',
      icon: 'close'
    }, {
      text: 'Forgot Password',
      icon: 'next',
      func: () => this.onForgot()
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

  private onForgot() {
    this.emailData.email = undefined;
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.Insert({ element: this.formEl.nativeElement }));
    this.store.dispatch(new superModalActions.Buttons([]));
    this.store.dispatch(new superModalActions.Show());
  }

  onEmailSubmit() {
    this.store.dispatch(new superModalActions.Hide());
    this.userService.accountRecovery(this.emailData.email).subscribe(
      () => this.onRecoverySuccess(),
      err => setTimeout(() => {
        this.onRecoveryError(err);
      }, 500)
    );
  }

  private onRecoverySuccess() {
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.Text('Check your email for instructions.'));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'OK',
      icon: 'close'
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

  private onRecoveryError(err: Error) {
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.HTML(err.message));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'OK',
      icon: 'close'
    }, {
      text: 'Try Again',
      icon: 'next',
      func: () => this.onForgot()
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

}
